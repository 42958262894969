import { useBreakpointValue } from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Icon, { IconProps } from '@legacy-vfuk/core-icon';
import Button, { AppearanceKeys } from '@legacy-vfuk/core-button';

import { getSrc } from 'gatsby-plugin-image';
import stringifyContent from '@/helpers/stringifyContent';

import { SWConfigureBusinessNeedsOptionProps } from '../SWConfigureBusinessNeeds.types';
import { OptionDestinationProps } from '../../SWConfigureBundleOptions/SWConfigureBundleOptions.types';
import {
  CtaButton,
  IconImage,
  IconImageWrapper,
  OptionHeader,
  OptionHeading,
  OptionText,
  OptionWrapper,
  boxOptionStyles,
  contentOptionStyles,
  iconTitleOptionStyles,
  titleOptionStyles,
} from './BundleNeedsOption.styles';

interface IBundleNeedsOption {
  option: SWConfigureBusinessNeedsOptionProps;
  handleButtonAction: (
    buttonDestinationType: OptionDestinationProps['destinationType'],
    configurationButtonDestinationLink: OptionDestinationProps['destinationLink'],
    buttonDestinationLink: OptionDestinationProps['destinationLink']
  ) => void;
}

const BundleNeedsOption = ({ option, handleButtonAction }: IBundleNeedsOption) => {
  const iconSize: IconProps['size'] = useBreakpointValue({ base: 3, md: 2 });
  const {
    optionHeader,
    optionBody,
    optionTextColor,
    backgroundColor,
    backgroundImage,
    hasBackgroundIcon = false,
    iconName,
    iconImage,
    buttonTitle,
    buttonAppearance,
    buttonDestinationType,
    buttonDestinationLink,
    configurationButtonDestinationLink,
  } = option;

  const getOptionHeaderIcon = () => {
    if (iconName) {
      return <Icon group="midLight" name={iconName} size={iconSize} />;
    }

    if (iconImage) {
      const srcIconImage = getSrc(iconImage);

      if (srcIconImage) {
        return (
          <IconImageWrapper>
            <IconImage
              sm={{
                src: srcIconImage,
                width: '48px',
                height: '48px',
              }}
              alt=""
            />
          </IconImageWrapper>
        );
      }
    }

    return '';
  };

  const getButtonappearance = (): AppearanceKeys => {
    if (buttonAppearance === 'secondary inverse') {
      return 'secondary' as AppearanceKeys;
    }

    return buttonAppearance as AppearanceKeys;
  };

  return (
    <OptionWrapper
      direction="column"
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage ? `url(${getSrc(backgroundImage)})` : 'none'}
      {...contentOptionStyles}
    >
      <OptionHeader {...boxOptionStyles}>
        {getOptionHeaderIcon()}
        <OptionHeading
          as="h3"
          color={optionTextColor}
          {...iconTitleOptionStyles(hasBackgroundIcon, !!(iconName ?? iconImage))}
        >
          {optionHeader}
        </OptionHeading>
      </OptionHeader>
      <OptionText as="h3" color={optionTextColor} flexDirection="column" {...titleOptionStyles}>
        {documentToReactComponents(JSON.parse(optionBody.raw))}
      </OptionText>
      <CtaButton>
        <Button
          width="full"
          appearance={getButtonappearance() ?? 'primary'}
          data-testid="configure-business-needs-button"
          inverse={buttonAppearance === 'secondary inverse'}
          htmlAttributes={{
            type: 'button',
          }}
          text={buttonTitle}
          onClick={() =>
            handleButtonAction(buttonDestinationType, configurationButtonDestinationLink, buttonDestinationLink)
          }
          dataSelectorPrefix={stringifyContent(JSON.parse(optionBody.raw)).toLowerCase()}
        />
      </CtaButton>
    </OptionWrapper>
  );
};

export default BundleNeedsOption;

import CardCarousel from '@legacy-vfuk/core-card-carousel';
import { FC } from 'react';

import SWPortfolioCard from '../SWPortfolioCard';
import * as Styles from './SWPortfolioCarousel.styles';
import { SWPortfolioCarouselProps } from './SWPortfolioCarousel.types';

const SWPortfolioCarousel: FC<SWPortfolioCarouselProps> = ({
  allowOverflow,
  arrows,
  cards,
  children,
  infiniteLoop,
  slidesToScroll,
  slidesToShow,
  srName,
  position,
}) => {
  const getSlidesToSHow = cards => {
    return cards.length > 1 ? { sm: 1.16, md: 1.45, lg: 2, xl: 2 } : { sm: 1.16, md: 1.45, lg: 1, xl: 1 };
  };
  return (
    <Styles.CarouselWrapper aria-label="region" position={position}>
      <CardCarousel
        slidesToShow={slidesToShow ?? getSlidesToSHow(cards)}
        slidesToScroll={slidesToScroll || { sm: 1, md: 1, lg: 1, xl: 1 }}
        // eslint-disable-next-line react/no-children-prop
        children={children || cards.map(card => <SWPortfolioCard key={card.id} {...card} />)}
        srName={srName || `Carousel`}
        {...(allowOverflow && { allowOverflow })}
        {...(arrows && { arrows })}
        {...(infiniteLoop && { infiniteLoop })}
      />
      <div className={'gradient'}></div>
    </Styles.CarouselWrapper>
  );
};

export default SWPortfolioCarousel;

import Button from '@legacy-vfuk/core-button';
import CardCarousel from '@legacy-vfuk/core-card-carousel';
import Heading from '@legacy-vfuk/core-heading';
import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SWArticleCard from '../../Elements/SWArticleCard';
import { CardWrapper, RelatedArticlesCarouselWrapper, WrapButton, WrapSimpleGrid } from './SWRelatedArticles.styles';
import { SWRelatedArticlesProps } from './SWRelatedArticles.types';

const SWRelatedArticles: FC<SWRelatedArticlesProps> = ({ items = [] }: SWRelatedArticlesProps) => {
  return (
    <WrapSimpleGrid>
      {items && items.length > 0 ? (
        <RelatedArticlesCarouselWrapper>
          <CardCarousel
            id="relatedArticlesCarousel"
            slidesToShow={{ sm: 1, md: 2, lg: 3, xl: 3 }}
            slidesToScroll={{ sm: 1 }}
            srName="RelatedArticles Carousel"
          >
            {items.map(card => (
              <CardWrapper key={uuidv4()}>
                <SWArticleCard {...card} />
              </CardWrapper>
            ))}
          </CardCarousel>
        </RelatedArticlesCarouselWrapper>
      ) : (
        <CardWrapper>
          <Heading level={3} justify="center" text="No articles found" />
        </CardWrapper>
      )}
      <WrapButton>
        <Button text="Vhub Knowledge Center" appearance="alt1" href="https://v-hub.vodafone.ie/knowledge-centre" />
      </WrapButton>
    </WrapSimpleGrid>
  );
};

export default SWRelatedArticles;

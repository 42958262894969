import { useTranslation } from 'react-i18next';
import SelectInput from '@legacy-vfuk/core-select-input';
import SimpleGrid from '@legacy-vfuk/core-simple-grid';
import { navigate } from 'gatsby';
import { FC, useMemo, useState } from 'react';

import { SWSelectProps } from './SWSectionSelect.types';
import { useLocation } from '@reach/router';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWSectionSelect: FC<SWSelectProps> = ({ products = [], selectOptions, heading = '' }) => {
  const { t } = useTranslation();
  const [category, setCategory] = useState<string>('');
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const filter = params.get('filter');

  const categoryOptions = useMemo(() => {
    if (selectOptions) {
      return selectOptions.map(item => ({
        text: item.optionText,
        value: item.optionValue,
      }));
    } else {
      return null;
    }
  }, products);

  const productOptions = useMemo(() => {
    if (!products) return [];
    if (category.trim() !== 'All Categories' && category.trim() !== '') {
      return products
        .filter(item => item.category?.name.trim() === category.trim())
        .map(product => ({
          text: product.name,
          value: product.slug,
        }));
    } else {
      const displayedProducts = filter ? products?.filter(item => item.category.slug === filter) : products;
      return displayedProducts.map(product => ({
        text: product.name,
        value: product.slug,
      }));
    }
  }, [category, products]);

  return (
    <SimpleGrid
      layout={{
        sm: [12],
        md: categoryOptions ? [5, 7] : [12],
      }}
      justify="center"
      spacing={2}
    >
      {categoryOptions && (
        <SelectInput
          options={categoryOptions}
          onChange={event => setCategory(event.target.value)}
          dataSelectorPrefix={heading.toLowerCase()}
        />
      )}

      <SelectInput
        placeholder={t('selectProduct')}
        options={productOptions}
        onChange={event => usingOpcoUrl(event.target.value, true)}
        dataSelectorPrefix={heading.toLowerCase()}
      />
    </SimpleGrid>
  );
};

export default SWSectionSelect;

import { UserAccount } from '@/store';

export enum OPCOOptions {
  SHOWHOME = 'SH',
  IRELAND = 'IE',
  PORTUGAL = 'PT',
}

export enum OPCONamesOptions {
  SHOWHOME_DEV = 'showhome dev',
  SHOWHOME_PROD = 'showhome prod',
  IRELAND_DEV = 'ireland dev',
  IRELAND_SIT = 'ireland dev',
  IRELAND_PP = 'ireland preprod',
  IRELAND_PROD = 'V-Hub Plus',
  PORTUGAL_DEV = 'portugal dev',
  PORTUGAL_SIT = 'portugal sit',
  PORTUGAL_PP = 'portugal pre prod',
  PORTUGAL_PROD = 'portugal prod',
}

export type UserServiceHeaderOptions = {
  customerAccountId: UserAccount['customerAccountId'];
  customerId: UserAccount['customerId'];
  companyName?: UserAccount['companyName'];
  barId?: UserAccount['barId'];
  segment?: UserAccount['segment'];
};

export type UserServiceHeaders = {
  'x-customer-account-id'?: UserAccount['customerAccountId'];
  'x-customer-id'?: UserAccount['customerId'];
  'x-bar-id'?: UserAccount['companyName'];
  'x-segment'?: UserAccount['barId'];
  'x-company-name'?: UserAccount['segment'];
};

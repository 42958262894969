import { Heading } from '@legacy-vfuk/core-heading';
import SimpleGrid from '@legacy-vfuk/core-simple-grid';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC } from 'react';

import SWFeatureOverviewCard from '../../Elements/SWFeatureOverviewCard/SWFeatureOverviewCard';
import SWSectionFiftyFiftyBanner from '../../Sections/SWSectionFiftyFiftyBanner';
import { SectionFeatureOverviewWrapper, SectionSWFeatureOverviewCardWrapper } from './SWFeatureOverview.styles';
import { SectionSWFeatureOverviewProps } from './SWFeatureOverview.types';

const SWFeatureOverview: FC<SectionSWFeatureOverviewProps> = ({
  featureOverviewCards,
  name,
  backgroundImage,
  backgroundColor,
  topBanner,
  bottomBanner,
}) => {
  const backgroundImageSrc = backgroundImage
    ? getSrc(backgroundImage) || getImage(backgroundImage)?.placeholder?.fallback
    : '';
  return (
    <SectionSWFeatureOverviewCardWrapper backgroundImageSrc={backgroundImageSrc} backgroundColor={backgroundColor}>
      {topBanner && <SWSectionFiftyFiftyBanner key={topBanner.id} {...topBanner} />}
      <Heading level={2} text={name} justify="center" />
      <SectionFeatureOverviewWrapper>
        <SimpleGrid
          layout={{
            xl: [6, 6],
            sm: [12, 12],
          }}
          spacing={7}
        >
          {featureOverviewCards.map(({ id, title, description, iconName }) => (
            <SWFeatureOverviewCard
              key={id}
              isWhiteIcons={!!backgroundImageSrc}
              title={title}
              description={description}
              iconName={iconName}
            />
          ))}
        </SimpleGrid>
      </SectionFeatureOverviewWrapper>
      {bottomBanner && <SWSectionFiftyFiftyBanner key={bottomBanner.id} {...bottomBanner} />}
    </SectionSWFeatureOverviewCardWrapper>
  );
};

export default SWFeatureOverview;

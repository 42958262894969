import { FC, useState } from 'react';
import { ButtonWrapper, ContentWrapper, RegisterAccountWrapper, RegisterTitle, Spacer } from './RegisterAccount.styles';
import StepperWithIcon from '../../maac-ui/StepperWithIcon/StepperWithIcon';
import { ContactDetails } from './RegisterForms/ContactDetails';
import { CompanyDetails } from './RegisterForms/CompanyDetails';
import Button from '@legacy-vfuk/core-button';
import SuccessPage from './SuccessPage';
import { FormStateProvider } from './RegisterForms/FormContext';
import { ButtonProps } from '@uk-source-web/button';
import { IFormState } from '@/components/pageBuilder/Elements/Form/Form.types';
import { registerUser, UserRegistrationPayload } from '@/services/user.services';
import { useModalContext } from '@/hooks/modal/useModal';

export type RegisterAccountProps = {
  id: string;
  name?: string;
  __typename: 'ContentfulNewAccountRegistrationFlow';
};

type RegisterForm = {
  company: IFormState | null;
  contact: IFormState | null;
  billing: IFormState | null;
};

const RegisterAccount: FC<RegisterAccountProps> = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [contactFormValid, setContactFormValid] = useState<boolean>(false);
  const [companyFormValid, setCompanyFormValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [registerForm, setRegisterForm] = useState<RegisterForm>({
    company: null,
    billing: null,
    contact: null,
  });

  const { setErrorInfo, toggleModal } = useModalContext();

  const handleFormSubmit = async () => {
    if (!registerForm.contact || !registerForm.company) {
      return;
    }
    const year = registerForm.contact['Date of birth'].value as string;
    const month = registerForm.contact['month'].value as string;
    const day = registerForm.contact['day'].value as string;
    const birthDate = new Date(year + '/' + month + '/' + day);

    const payload: UserRegistrationPayload = {
      user: {
        address1: registerForm.contact['Address 1'].value as string,
        address2: registerForm.contact['Address 2'].value as string,
        birthDate: birthDate.toISOString(),
        city: registerForm.contact['City'].value as string,
        country: registerForm.contact['Country'].value as string,
        eircode: registerForm.contact['Eircode'].value as string,
        email: registerForm.contact['Email address'].value as string,
        firstName: registerForm.contact['Name'].value as string,
        lastname: registerForm.contact['Last Name'].value as string,
        phoneNumber: registerForm.contact['Contact Phone'].value as string,
      },
      company: {
        address1: registerForm.company['Address 1'].value as string,
        address2: registerForm.company['Address 2'].value as string,
        city: registerForm.company['City'].value as string,
        businessType: registerForm.company['Type of Business'].value as string,
        companyName: registerForm.company['Company name'].value as string,
        companySize: registerForm.company['Company size'].value as string,
        country: registerForm.company['Country'].value as string,
        eircode: registerForm.company['Eircode'].value as string,
        iban: registerForm.billing?.IBAN.value as string,
        industryType: registerForm.company['Industry Type'].value as string,
      },
    };
    setLoading(true);
    const res = await registerUser(payload, setErrorInfo, toggleModal);
    setLoading(false);
    if (res && res.status == 200) {
      setCurrentStep(2);
    }
  };

  if (currentStep == 2) {
    return <SuccessPage />;
  }

  const validCheck = (isValid: boolean, formType: 'contact' | 'company' | 'billing') => {
    if (formType == 'contact') {
      setContactFormValid(isValid);
    } else if (formType == 'company') {
      setCompanyFormValid(isValid);
    }
  };

  const getButtonStatus = () => {
    if (currentStep == 0) {
      return (contactFormValid ? undefined : 'disabled') as ButtonProps['state'];
    } else {
      return (companyFormValid ? undefined : 'disabled') as ButtonProps['state'];
    }
  };

  return (
    <FormStateProvider>
      <RegisterAccountWrapper>
        <ContentWrapper>
          <RegisterTitle>New Account Registration</RegisterTitle>
          <Spacer h={50} />
          <StepperWithIcon
            steps={[
              { icon: { group: 'system', name: 'business' }, label: 'Contact details' },
              { icon: { group: 'system', name: 'bag' }, label: 'Company details' },
            ]}
            activeStep={currentStep}
            onStepClick={stepNumber => {
              setCurrentStep(stepNumber);
            }}
          />
          {currentStep == 0 ? (
            <ContactDetails
              validCallback={validCheck}
              onSubmit={contactForm => {
                setRegisterForm({
                  ...registerForm,
                  contact: contactForm,
                });
              }}
            />
          ) : (
            <CompanyDetails
              validCallback={validCheck}
              onSubmit={(form, formType) => {
                formType == 'billing'
                  ? setRegisterForm({
                      ...registerForm,
                      billing: form,
                    })
                  : setRegisterForm({
                      ...registerForm,
                      company: form,
                    });
              }}
            />
          )}
          <ButtonWrapper>
            <Button
              appearance="primary"
              width="full"
              state={getButtonStatus()}
              loading={loading}
              text={currentStep == 0 ? 'Continue' : 'Register'}
              onClick={
                currentStep == 0
                  ? () => {
                      setCurrentStep(1);
                    }
                  : () => {
                      handleFormSubmit();
                    }
              }
            />
          </ButtonWrapper>
        </ContentWrapper>
      </RegisterAccountWrapper>
    </FormStateProvider>
  );
};

export default RegisterAccount;

import React, { FC } from 'react';
import Image from '@legacy-vfuk/core-image';
import { Paragraph } from '@legacy-vfuk/core-paragraph';
import { getImage, getSrc } from 'gatsby-plugin-image';

import { ProductGridContainer, ProductIconWrapper, Subtitle } from './ProductGrid.styles';
import { ProductGridProps } from './ProductGrid.types';

const ProductGrid: FC<ProductGridProps> = ({ items, title }) => {
  return (
    <>
      {title && <Subtitle>{title}</Subtitle>}
      <ProductGridContainer>
        {items?.map(({ itemIcon, itemDescription }) => {
          const thumbnailIconSrc = getSrc(itemIcon) ?? getImage(itemIcon)?.placeholder?.fallback;

          return (
            <ProductIconWrapper key={itemDescription}>
              <Image
                alt=""
                sm={{
                  src: thumbnailIconSrc ?? '',
                  width: '48px',
                  height: '48px',
                }}
              />
              <Paragraph size={1}>{itemDescription}</Paragraph>
            </ProductIconWrapper>
          );
        })}
      </ProductGridContainer>
    </>
  );
};

export default ProductGrid;

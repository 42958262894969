import { Flex, useBoolean } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import CardCarousel from '@legacy-vfuk/core-card-carousel';
import Heading from '@legacy-vfuk/core-heading';
import Link from '@legacy-vfuk/core-link';
import Paragraph from '@legacy-vfuk/core-paragraph';
import StateNotification from '@legacy-vfuk/core-state-notification';
import { FC, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import SWPricingCard from '@/components/pageBuilder/Elements/SWPricingCard';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { addToBasket, useAppDispatch, useAppSelector } from '@/store';
import { ProductBundle } from '@/types/Product.types';

import SWLargePricingCard from '../../Elements/SWLargePricingCard/SWLargePricingCard';
import { SWPricingCardProps } from '../../Elements/SWPricingCard/SWPricingCard.types';
import { CarouselWrapper, NotificationWrapper, SubtitleWrapper } from './PricingCardCarousel.styles';
import { TPricingCardCarousel } from './PricingCardCarousel.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const PricingCardCarousel: FC<TPricingCardCarousel> = ({
  name,
  buttonsAction,
  products,
  itemStepName,
  stackableProduct,
  mainItem,
  bottomLink,
  subHeading,
  bottomLinkUrl,
  includesCards,
  typename,
}) => {
  const [cardHeaderHeight, setCardHeaderHeight] = useState<number>(0);
  const [notificationStatus, setNotificationStatus] = useState<boolean>(false);
  const [dxlProductInfo, setDxlProductInfo] = useState<ProductBundle[]>([]);
  const [parsedDxlProduct, setParsedDxlProduct] = useState<Required<SWPricingCardProps>['item'][]>([]);

  const { user } = useAppSelector(({ userSession }) => userSession);
  const dispatch = useAppDispatch();
  const { toggleModal } = useModalContext();

  const [isLoading, setLoading] = useBoolean(false);
  const editionIds: string[] = dxlProductInfo.map(edition => edition.productId);
  const { pathname, search } = useLocation();

  useEffect(() => {
    setNotificationStatus(!!itemStepName?.includes('Service Extra'));
  }, [notificationStatus]);

  const fetchProduct = (product: ProductBundle): Promise<ProductBundle> => new Promise(resolve => resolve(product));

  const isInArrayMiddle = (arrayLength: number, index: number) => {
    return Math.floor(arrayLength / 2) === index;
  };

  const shouldChangeTheme = pathname.includes('office-anywhere') && !pathname.includes('configuration');
  const shouldMergeProducts = pathname.includes('business-boost');

  const mergeSimilarProducts = () => {
    const similarProductsArray: Required<SWPricingCardProps>['item'][] = [];
    dxlProductInfo.forEach(product => {
      if (product.enableToggleOption) {
        const similarProduct = dxlProductInfo.find(
          item => item.name === `${product.name} Premium` && item.productId !== product.productId
        );
        if (
          similarProduct &&
          !similarProductsArray.find(
            item =>
              item.productId === similarProduct.productId || item.toggleVersion?.productId === similarProduct.productId
          )
        ) {
          similarProductsArray.push({
            ...product,
            toggleVersion: similarProduct,
          } as Required<SWPricingCardProps>['item']);
        }
        return;
      }
      similarProductsArray.push(product as Required<SWPricingCardProps>['item']);
    });
    setParsedDxlProduct(similarProductsArray);
  };

  const handleCardHeaderHeight = () => {
    const headersElements = Array.from(document.getElementsByClassName('cardHeaderElements'));

    let maxHeight = 0;

    headersElements.forEach(e => {
      const { clientHeight } = e;

      if (clientHeight > maxHeight) {
        maxHeight = clientHeight;
      }
    });

    typename === 'ContentfulSectionSwConfigureBundle' ? setCardHeaderHeight(0) : setCardHeaderHeight(maxHeight);
  };

  useEffect(() => {
    (async () => {
      setLoading.on();
      if (products && products.length > 0) {
        await Promise.all(
          products.map(async (product, index) => {
            const productBundle = await fetchProduct(product as ProductBundle);
            if (shouldChangeTheme && isInArrayMiddle(products.length, index)) {
              return { ...productBundle, middleProduct: true };
            }
            return productBundle;
          })
        )
          .then(productInfo => {
            setDxlProductInfo(productInfo);
            setLoading.off();
          })
          .catch(error => {
            isEnvDevelopment() && console.log('Error: ', error.message);
            setLoading.off();
          });
      } else {
        setLoading.off();
      }
    })();
  }, [products]);

  useEffect(() => {
    dxlProductInfo.length > 1 && shouldMergeProducts && mergeSimilarProducts();
    if (pathname.includes('product-list')) {
      handleAddToBasketWithQueryParams(parsedDxlProduct.length > 0 ? parsedDxlProduct : dxlProductInfo);
    }

    if (dxlProductInfo) {
      handleCardHeaderHeight();
    }
  }, [dxlProductInfo]);

  function getQueryParams() {
    const urlParams = new URLSearchParams(search);

    const productId = urlParams.get('productId');
    const qty = urlParams.get('qty');

    return { productId, qty };
  }

  const handleAddToBasketWithQueryParams = async (allProducts: Required<SWPricingCardProps>['item'][]) => {
    const { productId, qty } = getQueryParams();
    const queryProduct = allProducts.find(product => product.productId == productId);
    if (!queryProduct) {
      return;
    }

    if (productId && qty) {
      if (user?.userId) {
        dispatch(
          addToBasket({
            ...queryProduct,
            quantity: parseInt(qty),
          })
        );
      } else {
        toggleModal(true, 'Login');
      }
    } else {
      console.error('Invalid or missing query parameters.');
    }
  };

  const renderCards = (dxlProductInfo: ProductBundle[]) => {
    if (dxlProductInfo.length > 0 && !isLoading) {
      return (dxlProductInfo || [1, 2, 3]).map(product => {
        const minAmount = 5;
        return (
          <Flex key={product.id ? `${uuidv4()}-${product.id}` : uuidv4()} direction="column">
            {notificationStatus && !isLoading && (
              <NotificationWrapper justifyContent={'center'} mt={7} mb={8}>
                <StateNotification
                  state="info"
                  heading={{
                    text: 'Switch from your current email provider with confidence and get your emails & data transferred seamlessly to M365.',
                    level: 2,
                  }}
                />
              </NotificationWrapper>
            )}

            {product.__typename === 'ContentfulSectionSwLargePricingCard' ? (
              <SWLargePricingCard
                {...({ mainItem } as unknown as ProductBundle)}
                itemStepName={itemStepName}
                mergedProduct={shouldMergeProducts}
                item={{
                  ...product,
                  editionIds,
                  notificationStatus,
                  minAmount,
                  buttonsAction,
                }}
              />
            ) : (
              <SWPricingCard
                {...({ mainItem } as unknown as ProductBundle)}
                isMiddleOne={product.middleProduct}
                itemStepName={itemStepName}
                mergedProduct={shouldMergeProducts}
                includesCards={includesCards}
                item={{
                  ...product,
                  editionIds,
                  notificationStatus,
                  minAmount,
                  buttonsAction,
                }}
              />
            )}
          </Flex>
        );
      });
    } else if (isLoading) {
      return [1, 2, 3].map(item => (
        <SWPricingCard key={`${uuidv4()}-${item}`} isLoading={isLoading} itemStepName={itemStepName} />
      ));
    } else if (dxlProductInfo.length <= 0 && !isLoading) {
      return (
        <Flex direction="column">
          <Heading level={3} justify="center" text="No Products Found" />
        </Flex>
      );
    }
  };

  return (
    <CarouselWrapper stackableProduct={stackableProduct} cardHeaderHeight={cardHeaderHeight}>
      <CardCarousel
        key={uuidv4()}
        slidesToShow={{ sm: 1, md: 2, lg: 2, xl: 3 }}
        slidesToScroll={{ sm: 1 }}
        srName={`${name} Carousel`}
      >
        {renderCards(parsedDxlProduct.length > 0 ? parsedDxlProduct : dxlProductInfo)}
      </CardCarousel>
      {(subHeading || bottomLink) && (
        <SubtitleWrapper>
          {subHeading && <Paragraph justify="center">{subHeading}</Paragraph>}
          {bottomLink && (
            <Link href={usingOpcoUrl(bottomLinkUrl)} text={bottomLink} showUnderline={true} appearance="primary" />
          )}
        </SubtitleWrapper>
      )}
    </CarouselWrapper>
  );
};

export default PricingCardCarousel;

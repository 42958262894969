import Heading from '@legacy-vfuk/core-heading';
import LinkWithIcon from '@legacy-vfuk/core-link-with-icon';
import Paragraph from '@legacy-vfuk/core-paragraph';
import Pill from '@legacy-vfuk/core-pill';
import React from 'react';

import { Buttons, Wrapper } from './SWProductDescription.styles';
import { SWProductDescriptionProps } from './SWProductDescription.types';

const SWProductDescription = ({
  subheading,
  pillText,
  description,
  firstButton,
  secondButton,
  apiData,
}: SWProductDescriptionProps) => {
  return (
    <Wrapper data-selector="product page title">
      <Heading size={3} level={1} text={apiData?.name} />
      <Heading size={2} text={subheading} />
      <Pill text={pillText} />
      <Paragraph appearance="primary">{description}</Paragraph>

      <Buttons>
        {firstButton && (
          <LinkWithIcon
            icon={{
              name: firstButton.iconName,
              justify: firstButton.iconJustify,
            }}
            text={firstButton.buttonText}
            htmlAttributes={{
              target: '_blank',
            }}
            appearance="primary"
            onClick={() => {
              window.open(firstButton.href);
            }}
          />
        )}
        {secondButton && (
          <LinkWithIcon
            icon={{
              name: secondButton.iconName,
              justify: secondButton.iconJustify,
            }}
            text={secondButton.buttonText}
            htmlAttributes={{
              target: '_blank',
            }}
            appearance="primary"
            onClick={() => {
              window.open(secondButton.href);
            }}
          />
        )}
      </Buttons>
    </Wrapper>
  );
};

export default SWProductDescription;

import { Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import Loader from '@legacy-vfuk/core-loader';

import { useAppSelector } from '@/store';

const SpinnerModal = () => {
  const { onClose } = useDisclosure();
  const { isLoading } = useAppSelector(({ userSession }) => userSession) || true;
  const { pathname } = useLocation();
  const multipleBillingAccountPage = pathname.includes('multiple-billing-account');

  return (
    <Modal isCentered isOpen={isLoading && multipleBillingAccountPage} onClose={onClose} data-testid="spinner-modal">
      <ModalOverlay />
      <ModalContent maxW="max-content">
        <ModalBody>
          <Loader />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SpinnerModal;

import { Button } from '@legacy-vfuk/core-button';
import { useLocation } from '@reach/router';
import { useBasket } from '@/hooks';

import QuantityInput from './QuantityInput';
import { ProductCtaProps } from './ProductCta.types';
import { ButtonWrapper } from './ProductCta.styles';

const ProductCta = ({ product, buttonsAction, nextStep }: ProductCtaProps) => {
  const { pathname } = useLocation();

  const { quantity, buttonText, handleChangeQuantity, handleDecreaseQty, handleIncreaseQty, handleButtonAction } =
    useBasket({
      item: { ...product, buttonsAction },
      nextStep,
      pathname,
    });

  return (
    <>
      <QuantityInput
        handleChangeQuantity={handleChangeQuantity}
        handleDecreaseQty={handleDecreaseQty}
        handleIncreaseQty={handleIncreaseQty}
        quantity={quantity}
      />
      <ButtonWrapper>
        <Button
          onClick={e => handleButtonAction(e)}
          id="buy-button"
          text={buttonText()}
          data-selector="SWTariffCardButtons"
        />
      </ButtonWrapper>
    </>
  );
};

export default ProductCta;

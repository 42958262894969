import { Tab } from '@legacy-vfuk/core-tabs';
import SWSectionServicesBanner from '@/components/pageBuilder/Sections/SWSectionServicesBanner';
import SWSectionTariffCards from '@/components/pageBuilder/Sections/SWSectionTariffCards';

import {
  CONTENTFUL_SECTION_SW_SERVICES_BANNER,
  CONTENTFUL_SECTION_SW_TARIFF_CARDS,
} from '@/templates/page-builder-helpers';

import { SWSectionTabProps, SWSectionTabsProps, TabSectionProps } from './SWSectionTabs.types';
import { TabsContainer, TabsWrapper } from './SWSectionTabs.styles';

const SWSectionTabs = ({ tabs }: SWSectionTabsProps) => {
  const getTabSections = (section: TabSectionProps, tab: SWSectionTabProps) => {
    switch (section.__typename) {
      case CONTENTFUL_SECTION_SW_SERVICES_BANNER:
        return <SWSectionServicesBanner key={section.id} {...section} />;
      case CONTENTFUL_SECTION_SW_TARIFF_CARDS:
        return <SWSectionTariffCards key={section.id} {...section} />;
      default:
        return <></>;
    }
  };

  return (
    <TabsContainer>
      <TabsWrapper appearance={'primary'} key="primary">
        {tabs.map(tab => {
          const { value, label, name } = tab;
          return (
            <Tab
              key={`${name}-tab-${label}`}
              text={label}
              id={`appearance-${name}-tab-${label}`}
              data-selector="SWSectionTabs"
            >
              {value.map(item => getTabSections(item, tab))}
            </Tab>
          );
        })}
      </TabsWrapper>
    </TabsContainer>
  );
};

export default SWSectionTabs;

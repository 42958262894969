import Button from '@legacy-vfuk/core-button';
import Image from '@legacy-vfuk/core-image';
import LoadingSpinner from '@legacy-vfuk/core-loading-spinner';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC, useState } from 'react';

import DetailsAndPricing from '../../CardElements/DetailsAndPricing';
import InfoAndCta from '../../CardElements/InfoAndCta';
import { CardHeading, CTAWrapper, ImageWrap, LoadingWrapper } from '../../SWPricingCard.styles';
import { CardTypeProps } from '../../SWPricingCard.types';

import {
  FCWrapper,
  FlippedDescription,
  FlippedShortDescription,
  FlippedWrapper,
  FlippingCardHeader,
  FlippingCardTitle,
  FlippingCardTitleWrapper,
} from './SWFlippingCard.styles';
import usingOpcoUrl from '@/helpers/prefixHelper';

import { DetailsAndPricingProps } from '../../CardElements/DetailsAndPricing/DetailsAndPricing.types';
const SWFlippingCard: FC<CardTypeProps> = ({ item, mainItem, itemStepName, shouldChangeTheme, mergedProduct }) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [selectedEdition, setSelectedEdition] = useState<DetailsAndPricingProps['selectedEdition']>();

  const { productPageUrl, shortDescription, description, thumbnailIcon, detailAndPricing, dxlInformation } = item;
  const thumbnailIconSrc = getSrc(thumbnailIcon) || getImage(thumbnailIcon)?.placeholder?.fallback || '';

  const handleFlip = () => setIsFlipped(!isFlipped);

  return (
    <>
      {dxlInformation ? (
        <CardHeading px={5}>
          <FlippingCardHeader direction="row" spacing={5}>
            {thumbnailIconSrc && (
              <ImageWrap>
                <Image
                  alt={thumbnailIcon?.title || ''}
                  sm={{
                    src: thumbnailIconSrc,
                    width: '48px',
                    height: '48px',
                  }}
                  md={{
                    src: thumbnailIconSrc,
                    width: '64px',
                    height: '64px',
                  }}
                />
              </ImageWrap>
            )}
            <FlippingCardTitleWrapper>
              <FlippingCardTitle as="h2" color={shouldChangeTheme ? 'white' : 'mineShaft'}>
                {dxlInformation?.name}
              </FlippingCardTitle>
            </FlippingCardTitleWrapper>
          </FlippingCardHeader>
        </CardHeading>
      ) : (
        <LoadingWrapper>
          <LoadingSpinner appearance="primary" size={5} />
        </LoadingWrapper>
      )}

      <FCWrapper isFlipped={isFlipped}>
        {isFlipped ? (
          <FlippedWrapper isFlipped={isFlipped}>
            <FlippedDescription
              fontSize={{ base: 'md', md: 'lg' }}
              marginRight={5}
              marginLeft={5}
              mt={{ base: 5, md: 0 }}
            >
              {description?.description}
            </FlippedDescription>
            <Button
              text={'Visit product page'}
              appearance="primary"
              href={usingOpcoUrl(productPageUrl || '/marketplace/product-list')}
              onClick={() => {
                return; // Just to not make it blank and still get the styling
              }}
              id={'VisitProductButton'}
            />
            <Button text={'Show Less'} appearance="alt1" onClick={handleFlip} id={'FlipButton'} />
          </FlippedWrapper>
        ) : (
          <>
            <FlippedShortDescription
              color={'mineShaft'}
              fontSize={{ base: 'md', md: 'lg' }}
              marginRight={5}
              marginLeft={5}
              mt={{ base: 5, md: 0 }}
            >
              {shortDescription?.shortDescription}
            </FlippedShortDescription>
            {dxlInformation && detailAndPricing && (
              <DetailsAndPricing
                itemStepName={itemStepName}
                item={item}
                color={shouldChangeTheme ? 'white' : undefined}
                useSimplePrice={mergedProduct}
                {...{ selectedEdition, setSelectedEdition }}
              />
            )}
            <Button text={'Show More'} appearance="alt1" onClick={handleFlip} id={'FlipButton'} />
          </>
        )}
      </FCWrapper>
      <CTAWrapper>
        {item?.dxlInformation && (
          <InfoAndCta
            {...{ mainItem, itemStepName }}
            item={{ ...item, buttonsAction: 'updateBasketWithQty' }}
            buttonStyling={{
              backgroundColor: undefined,
              textColor: undefined,
            }}
            useSimplePrice={mergedProduct}
          />
        )}
      </CTAWrapper>
    </>
  );
};

export default SWFlippingCard;

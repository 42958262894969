import { FC, useState } from 'react';
import { CreditVettingFormProps } from './CreditVettingForm.types';
import { ElementWrapper, CVFDescription, CVFTitle, CreditVettingWrapper } from './CreditVettingForm.styles';
import Button from '@legacy-vfuk/core-button';
import { DateTextInputWithLabel } from '@uk-source-web/date-text-input-with-label';
import { Spacer } from '@/components/Global/RegisterAccount/RegisterAccount.styles';
import { creditVettingProductOrder, CreditVettingPayload } from '@/services/product.services';
import { useModalContext } from '@/hooks/modal/useModal';
import usingOpcoUrl from '@/helpers/prefixHelper';

interface CreditVettingFormDateInput {
  date: Date | 'error' | null;
  dateString: string;
}

export const CreditVettingForm: FC<CreditVettingFormProps> = ({ buttonText, description, title, invertTheme }) => {
  const [bankStartDate, setBankStartDate] = useState<CreditVettingFormDateInput | null>(null);
  const [businessStartDate, setBusinessStartDate] = useState<CreditVettingFormDateInput | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);

  const { setErrorInfo, toggleModal } = useModalContext();

  const handleBankStartDayChange = (value: Date | 'error' | null, dateString: string) => {
    if (value && dateString)
      setBankStartDate({
        date: value,
        dateString: dateString,
      });
  };

  const handleBusinessStartDayChange = (value: Date | 'error' | null, dateString: string) => {
    if (value && dateString)
      setBusinessStartDate({
        date: value,
        dateString: dateString,
      });
  };

  const handleCreditVettingSubmit = async () => {
    if (bankStartDate == null || businessStartDate == null) {
      return;
    }
    const bankStartDateParts = bankStartDate.dateString.split('/');
    const businessStartDateParts = businessStartDate.dateString.split('/');

    if (bankStartDateParts.length != 3 || businessStartDateParts.length != 3) {
      return;
    }
    const payload: CreditVettingPayload = {
      bankStartDay: `${bankStartDateParts[2]}-${bankStartDateParts[1]}-${bankStartDateParts[0]}`,
      businessStartDate: `${businessStartDateParts[2]}-${businessStartDateParts[1]}-${businessStartDateParts[0]}`,
    };
    const res = await creditVettingProductOrder(payload, setErrorInfo, toggleModal);
    if (res) {
      return usingOpcoUrl(`/new-order-flow-success-page?id=${res.data.id}&email=${res.data.userEmail}`, true);
    }
  };

  const handleSubmitClick = async () => {
    setIsLoading(true);
    await handleCreditVettingSubmit();
    setIsLoading(false);
  };

  return (
    <CreditVettingWrapper invertTheme={invertTheme}>
      <CVFTitle invertTheme={invertTheme}>{title}</CVFTitle>
      <Spacer w={100} h={12} />
      <CVFDescription invertTheme={invertTheme}>{description}</CVFDescription>
      <Spacer w={100} h={60} />

      <ElementWrapper invertTheme={invertTheme}>
        <DateTextInputWithLabel
          dateTextInput={{
            value: bankStartDate?.date || null,
            onChange: handleBankStartDayChange,
            id: 'bank-start-date',
            name: 'bankStartDate',
            onInvalidInputEntry: errors => console.log(errors),
            labels: { day: 'day', month: 'month', year: 'year' },
          }}
          fieldWrapper={{
            label: 'Bank start day',
          }}
        />
      </ElementWrapper>
      <Spacer w={100} h={15} />

      <ElementWrapper invertTheme={invertTheme}>
        <DateTextInputWithLabel
          dateTextInput={{
            value: businessStartDate?.date || null,
            onChange: handleBusinessStartDayChange,
            id: 'business-start-date',
            name: 'businessStartDate',
            onInvalidInputEntry: errors => console.log(errors),
            labels: { day: 'day', month: 'month', year: 'year' },
          }}
          fieldWrapper={{
            label: 'Business start day',
          }}
        />
      </ElementWrapper>
      <Spacer w={100} h={50} />

      <ElementWrapper invertTheme={invertTheme}>
        <Button appearance="primary" width="full" text={buttonText} onClick={handleSubmitClick} loading={loading} />
      </ElementWrapper>
    </CreditVettingWrapper>
  );
};

import {
  Button,
  chakra,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Icon from '@legacy-vfuk/core-icon';
import axios from 'axios';
import { navigate } from 'gatsby';
import { rem } from 'polished';
import { ReactNode, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

import { logout } from '@/services/auth.services';
import { clearUserSession, deleteBasket, useAppDispatch, useAppSelector } from '@/store';
import { PageBuilderProps } from '@/templates/page-builder';
import usingOpcoUrl from '@/helpers/prefixHelper';
import handleTranslation from '@/helpers/handleTranslation';

type CheckIdleTimeProps = Pick<PageBuilderProps['pageBuilderData'], 'isProtectedPage'> & {
  children?: ReactNode;
};
const CheckIdleTime = ({ isProtectedPage, children }: CheckIdleTimeProps) => {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAppSelector(({ userSession }) => userSession);
  // Set timeout values for user logout
  const timeout = 1000 * 60 * 7; // Calc a timeout about 10min for the user to keep active
  const promptTimeout = 1000 * 60 * 3; // Prompt timeout after 7min.

  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal open state for timeout
  const [remaining, setRemaining] = useState(0); // Time before idle
  const sessionTimeOut = handleTranslation('Session Timeout','Sessão terminada')
  const yourSession = handleTranslation('Your session','A sua sessão')
  const wasTimedOut = handleTranslation('was timed out!','terminou!')
  const wiilExpireSoon = handleTranslation('will expire soon','irá expirar em breve')
  const sessionParagraph = handleTranslation('We’re sorry to let you know, but your session was timed out. To be able to complete your purchase, you will have to start from the beginning.',
  'Informamos que a sua sessão terminou. Para continuar a sua compra, terá que voltar ao inicio.')
  const subSessionParagraph = handleTranslation('Your session will expire soon and you’ll be logged out. By logging out, the products from your basket will be removed. To be able to finish your purchase, press the Continue button.',
  'A sua sessão expirará em breve e será desligado. Ao sair, os produtos do seu carrinho serão removidos. Para poder finalizar a sua compra, pressione o botão Continuar.')
  const closeBtn = handleTranslation('Close','Fechar')
  const continueBtn = handleTranslation('Continue','Continuar')

  const onPrompt = () => {
    onOpen();
    setRemaining(timeout - promptTimeout);
  };

  const onIdle = async () => {
    // Here the user will be inactive then clear his session
    setRemaining(0);
    const res = await logout();

    if (!axios.isAxiosError(res) && res.status === 204) {
      dispatch(clearUserSession());
      dispatch(deleteBasket());
    }
  };

  const onActive = () => {
    onClose();
    setRemaining(0);
  };

  const handleStillHere = () => {
    onClose();
    activate();
  };

  const handleOnClose = () => {
    onClose();
    usingOpcoUrl('/marketplace/multiple-billing-account', true);
  };

  const { getRemainingTime, isPrompted, activate, isIdle, start } = useIdleTimer({
    startManually: true,
    timeout,
    promptTimeout,
    crossTab: true,
    stopOnIdle: true,
    name: 'logout-timer',
    onPrompt,
    onIdle,
    onActive,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.userId && isPrompted()) {
        setRemaining(getRemainingTime());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [getRemainingTime, isPrompted, isIdle]);

  useEffect(() => {
    if (user?.userId) {
      start();
    }
    if (!user?.userId && !!isProtectedPage) {
      usingOpcoUrl('/marketplace/multiple-billing-account', true);
    }
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleOnClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent borderRadius={0} maxW={rem('500px')}>
          <ModalHeader
            display="flex"
            alignItems="center"
            bg={remaining === 0 ? 'primary1.default' : '#bdbbbb'}
            paddingBlock={rem('4.5px')}
            paddingInline={5}
          >
            <Flex
              mr={5}
              sx={{
                svg: {
                  h: rem('26px'),
                  w: rem('27px'),
                  strokeWidth: rem('2px'),
                },
              }}
            >
              <Icon name="warning" group="system" appearance={remaining === 0 ? 'primary' : 'secondary'} inverse />
            </Flex>
            <chakra.span
              color={remaining === 0 ? 'primary2.default' : 'primary1.default'}
              fontSize="3xl"
              fontWeight="normal"
              lineHeight={rem('35px')}
            >
              {sessionTimeOut}
            </chakra.span>
            <chakra.span
              color={remaining === 0 ? 'primary2.default' : 'primary1.default'}
              fontSize="xl"
              lineHeight={rem('23px')}
              ml="auto"
              opacity={remaining === 0 ? '0.5' : '1'}
            >
              {new Date(remaining).toISOString().slice(14, 19)}
            </chakra.span>
          </ModalHeader>

          <ModalBody paddingBlock={4} paddingInline={5}>
            <Heading fontSize="xl" fontWeight="normal" lineHeight={rem('23px')} mb={5}>
              {yourSession} {remaining === 0 ? wasTimedOut : wiilExpireSoon}!
            </Heading>
            <Text fontSize="md" fontWeight="normal" m={0} lineHeight={rem('18px')}>
              {remaining === 0
                ? sessionParagraph
                : subSessionParagraph}
            </Text>
          </ModalBody>

          <ModalFooter paddingBlock={4} paddingInline={5}>
            <Button
              bg={remaining === 0 ? 'primary1.default' : '#3C9A35'}
              borderRadius={0}
              color="white"
              fontSize={rem('20px')}
              fontWeight="normal"
              lineHeight={rem('23px')}
              onClick={remaining === 0 ? handleOnClose : handleStillHere}
              paddingBlock={0}
              paddingInline={5}
              w={rem('112px')}
              h={10}
              variant="solid"
            >
              {remaining === 0 ? closeBtn : continueBtn}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {(!user?.userId || isLoading) && !isOpen && !!isProtectedPage ? (
        <></>
      ) : (
        <Flex direction="column">{children}</Flex>
      )}
    </>
  );
};

export default CheckIdleTime;

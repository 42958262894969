import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, useDisclosure } from '@chakra-ui/react';

import Icon from '@legacy-vfuk/core-icon';

import { ExtraInformationProps } from './ExtraInformation.types';
import {
  ContentWrapper,
  ExtraInformationButton,
  ExtraInformationText,
  ExtraInformationWrapper,
  IconWrapper,
  TextProps,
} from './ExtraInformation.styles';

const ExtraInformation = ({ extraInformation, ...props }: ExtraInformationProps) => {
  const { isOpen, onToggle } = useDisclosure();
  const [content] = extraInformation;

  return (
    <ExtraInformationWrapper bg={isOpen ? '#666666' : '#f2f2f2'} h="full" {...props}>
      <ExtraInformationText as={Box} {...(isOpen && { display: 'none' })} color="mineShaft" {...TextProps}>
        {content.textOne && documentToReactComponents(JSON.parse(content.textOne.raw))}
      </ExtraInformationText>

      <ExtraInformationText as={Box} {...(!isOpen && { display: 'none' })} color="white" {...TextProps}>
        {content.textTwo && documentToReactComponents(JSON.parse(content.textTwo.raw))}
      </ExtraInformationText>

      {content.textTwo && (
        <ContentWrapper flexDir={isOpen ? 'row-reverse' : 'row'}>
          <ExtraInformationButton
            onClick={onToggle}
            color={isOpen ? 'white' : 'black'}
            width="fit-content"
            fontWeight="hairline"
            mb="4"
          >
            {`Show ${isOpen ? 'less' : 'more'}`}
          </ExtraInformationButton>

          <IconWrapper transform={isOpen ? 'rotateY(180deg)' : 'none'}>
            <Icon appearance="secondary" group="system" name={'chevron-right'} />
          </IconWrapper>
        </ContentWrapper>
      )}
    </ExtraInformationWrapper>
  );
};

export default ExtraInformation;

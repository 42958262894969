import { useState } from 'react';
import { IconButton } from '@uk-source-web/icon-button';
import { updateUserBasket, useAppDispatch } from '@/store';

import { BasketItemProps } from '../SWBasket.types';
import { PRODUCT_BUNDLE_TYPENAME } from '../SWBasket.helper';

import QuantitySelectorStyles, {
  QuantitySelectorActions,
  QuantitySelectorLabel,
  QuantitySelectorValue,
} from './BasketQuantitySelector.styles';

interface QuantitySelectorProps {
  label: string;
  item: BasketItemProps;
}

const QuantitySelector = ({ item, label }: QuantitySelectorProps) => {
  const dispatch = useAppDispatch();
  const [currentQuantity, setCurrentQuantity] = useState<number>(item.quantity || 0);

  const singleItem = item.buttonsAction === 'addToBasket';
  const isProductBundle = item.__typename === PRODUCT_BUNDLE_TYPENAME;
  const minAmount = isProductBundle && item?.msftProduct ? 5 : 1;

  const serviceExtra =
    isProductBundle && item?.msftProduct && item?.configTab === 'Service Extra' && currentQuantity <= 5;

  const microsoftMigration = currentQuantity === 5 && minAmount === 5 && item.name === 'Microsoft 365 Migration';

  const disableActions = !(item.increaseDecreaseAct && !singleItem);

  const decreaseActionIsDisabled = disableActions || serviceExtra || microsoftMigration || currentQuantity === 1;
  const increaseActionIsDisabled = disableActions;

  const handleDecreaseQty = () => {
    if (currentQuantity && currentQuantity > 0) {
      dispatch(
        updateUserBasket({
          ...item,
          quantity: isProductBundle && item.msftProduct && currentQuantity === 5 ? minAmount : currentQuantity - 1,
        })
      );
      if (microsoftMigration) {
        setCurrentQuantity(minAmount);
      } else {
        setCurrentQuantity(currentQuantity - 1);
      }
    }
  };

  const handleIncreaseQty = () => {
    if (currentQuantity > 0) {
      dispatch(
        updateUserBasket({
          ...item,
          quantity: currentQuantity + 1,
        })
      );
      setCurrentQuantity(currentQuantity + 1);
    }
  };

  return (
    <QuantitySelectorStyles>
      <QuantitySelectorLabel>{label}</QuantitySelectorLabel>
      <QuantitySelectorActions>
        <IconButton
          size={1}
          shape="circle"
          icon={{ name: 'minus' }}
          onClick={handleDecreaseQty}
          srText="decrease"
          state={decreaseActionIsDisabled ? 'disabled' : undefined}
        />
        <QuantitySelectorValue>
          {!item.increaseDecreaseAct && item.quantity ? item.quantity : currentQuantity}
        </QuantitySelectorValue>
        <IconButton
          size={1}
          shape="circle"
          icon={{ name: 'plus' }}
          onClick={handleIncreaseQty}
          srText="increase"
          state={increaseActionIsDisabled ? 'disabled' : undefined}
        />
      </QuantitySelectorActions>
    </QuantitySelectorStyles>
  );
};

export default QuantitySelector;

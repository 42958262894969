import styled from 'styled-components';
import { rem } from 'polished';

export const BillingAccountsInner = styled.div`
  padding: ${rem('20px')} 0 ${rem('80px')};
  max-width: ${rem('840px')};

  [class*='SimpleGridstyle__SimpleGrid-sc'] {
    margin: 0 auto;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      width: auto;
    }

    [class*='IconCardstyle__IconCard-sc'] {
      background: ${({ theme }) => theme.color.primary2.default};

      [class*='CardBodystyle__CardBody-sc'] {
        padding: ${rem('6px')} ${rem('16px')} ${rem('16px')};

        h2 {
          font-size: ${rem('24px')};
          line-height: ${rem('31px')};
        }

        p {
          font-size: ${rem('18px')};
          line-height: ${rem('20px')};
          max-width: ${rem('270px')};
          margin: 0 auto;
          font-family: 'VodafoneLight';
        }
      }
    }
  }

  [class*='FieldWrapperstyle__FieldWrapper-sc'] {
    max-width: initial;
  }

  [class*='FieldWrapperstyle__InputWrapper-sc'] {
    [class*='RadioButtonListFieldSetstyle__RadioButtonList'] {
      width: fit-content;
      margin: 0 auto;

      [class*='Radiostyle__LabelText-sc'] {
        font-size: ${rem('18px')};
        line-height: ${rem('20px')};
      }
    }
  }
`;

export const BillingAccountsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${rem('20px')};
  background: ${({ theme }) => theme.color.primary2.default};
  min-height: calc(100vh - ${rem('58px')});
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-height: calc(100vh - ${rem('108px')});
  }
`;

export const BillingAccountsParagraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  max-width: ${rem('680px')};
`;

import styled from '@emotion/styled';
import Container from '@/components/Global/Container';

export const StandardBannerContainer = styled(Container)``


export const BannerCarouselStyles = styled.div`
  width: 100%;
[data-component-name="BannerCarousel"] {
#prev-arrow ,
#next-arrow{
display:none;
}
}
  `
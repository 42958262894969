import { HeadingStyles } from '@uk-source-web/heading';

import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { respondTo, spacing } from '@uk-source-web/mixins';

export const BasketSummaryRoot = styled.div`
  > * {
    position: relative;
    z-index: 4 !important;
  }
`;
export const BasketSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem('-10px')};
  margin-bottom: ${rem('-10px')};
`;

export const BasketSummaryTemplateContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: ${rem('1180px')};
  margin: 0 auto;
`;

export const BasketContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

export const BasketSummaryHeading = styled.div`
  margin-right: auto;
  flex-shrink: 0;

  ${spacing('padding-right', 3)};

  ${respondTo.md(css`
    display: flex;
    align-items: center;

    ${HeadingStyles.Heading} {
      ${spacing('margin-right', 6)};
    }
  `)}
`;

import { useState } from 'react';
import { rem } from 'polished';
import axios from 'axios';

import Button from '@legacy-vfuk/core-button';
import { InteractionEvent } from '@uk-source-web/interaction';

import { useModalContext } from '@/hooks/modal/useModal';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { sendLiveFormEmail } from '@/services/mail.services';
import { TError } from '@/services/error.types';

import FormModal from './FormModal';

import { SWFormModalProps } from './SWFormModal.types';
import { IFormState } from '../../Elements/Form/Form.types';
import { FormModalContent, FormModalOverlay, ModalForm, SWFormButton } from './SWFormModal.styles';
import FormSuccess from './FormSuccess';

const SWFormModal = ({ button, buttonIconName, form }: SWFormModalProps) => {
  const { isModalOpen, toggleModal, setErrorInfo } = useModalContext();

  const [submitForm, setSubmitForm] = useState<IFormState>({});
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [submitComplete, setSubmitComplete] = useState<boolean>(false);

  const formSubmit = (currentForm: IFormState) => {
    setSubmitForm(currentForm);
  };

  const formatErrorMessage = (message: string | undefined) => {
    if (!message) return '';

    const removedBrackets = message.slice(1, -1);
    const friendlyMessage = removedBrackets.replace(/,(?=[^,]*$)/, ' and');

    return friendlyMessage;
  };

  const handleSubmitAction = async (e: InteractionEvent) => {
    const { onSubmitAction } = form;

    if (onSubmitAction === 'sendLiveFormEmail') {
      e.preventDefault();
      try {
        setSubmitLoading(true);
        const sendEmail = await sendLiveFormEmail(submitForm);
        if (!axios.isAxiosError(sendEmail) && sendEmail.status === 200) {
          setSubmitComplete(true);
        } else {
          if (axios.isAxiosError(sendEmail)) {
            setErrorInfo({
              statusType: sendEmail.response?.data?.error,
              statusCode: sendEmail.response?.data?.code || sendEmail.response?.status,
              requestUrl: 'mail',
              errorMessage: formatErrorMessage(sendEmail.response?.data?.message),
            });
          }
          toggleModal(true, 'Error');
        }
        setSubmitLoading(false);
      } catch (err) {
        setSubmitLoading(false);
        const error = err as TError;
        isEnvDevelopment() && console.log(error?.response?.data.error);
      }
    }
  };

  return (
    form && (
      <>
        <SWFormButton>
          <Button
            text={button}
            icon={buttonIconName ? { name: buttonIconName, justify: 'left' } : undefined}
            onClick={() => toggleModal(true)}
          />
        </SWFormButton>

        <ModalForm
          isCentered
          size="4xl"
          isOpen={isModalOpen}
          onClose={() => toggleModal(false)}
          closeOnOverlayClick={false}
          data-testid="swFormModal"
        >
          <FormModalOverlay bgColor="#212121" />
          <FormModalContent
            borderRadius={rem('6px')}
            boxShadow="none"
            margin={rem('16px')}
            maxHeight={`calc(100vh - ${rem('40px')})`}
          >
            {!submitComplete ? (
              <FormModal
                form={form}
                buttonSubmitLoading={submitLoading}
                handleForm={formSubmit}
                handleSubmitAction={handleSubmitAction}
              />
            ) : (
              <FormSuccess handleCloseForm={() => toggleModal(false)} />
            )}
          </FormModalContent>
        </ModalForm>
      </>
    )
  );
};

export default SWFormModal;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { rem } from 'polished';

import { useLocation } from '@reach/router';
import RadioButtonList from '@legacy-vfuk/core-radio-button-list';

import { costOnePrice, costTwoPrice } from '@/helpers/findPrices';
import { ProductBundleDetailAndPricing } from '@/types/Product.types';

import { DetailsAndPricingProps } from './DetailsAndPricing.types';
import { DetailsAndPricingWrapper, DetailsItem } from './DetailsAndPricing.styles';
import { DetailsInfo, DetailsTitle } from './DetailsAndPricingElements';
import handleTranslation from '@/helpers/handleTranslation';

const DetailsAndPricing = ({
  itemStepName,
  item,
  color,
  useSimplePrice,
  selectedEdition,
  checkedEditionItem,
  handleSelectedEdition,
}: DetailsAndPricingProps) => {
  const {
    currencySymbol,
    firstEntry,
    firstEntryValue,
    secondEntry,
    secondEntryValue,
    thirdEntryValueColor,
    fourthEntry,
    useCustomThirdEntryValue,
  } = item?.detailAndPricing as ProductBundleDetailAndPricing;
  const withIncludedBundles = item.bundleIncludes && item.bundleIncludes.length > 0;
  const withExtraInformation = item.extraInformation && item.extraInformation.length > 0;
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const thirdEntry = useMemo(() => {
    if (checkedEditionItem && !useCustomThirdEntryValue) {
      const text = item.detailAndPricing?.thirdEntry?.split(' ').slice(1).join(' ');
      return `${startCase(checkedEditionItem.type.toLowerCase())} ${text}`;
    } else {
      return item.detailAndPricing?.thirdEntry;
    }
  }, [item, checkedEditionItem]);

  const thirdEntryPrice = useMemo(() => {
    if (useSimplePrice) {
      return costOnePrice(item.dxlInformation?.price);
    } else if (checkedEditionItem) {
      return costOnePrice(checkedEditionItem.prices);
    } else if (
      (!useSimplePrice && itemStepName === 'Service Extra') ||
      pathname.includes('apphelp-microsoft-365-migration')
    ) {
      return costTwoPrice(item.dxlInformation?.price);
    } else {
      if (useCustomThirdEntryValue) return item.detailAndPricing?.thirdEntryValue;
      return costOnePrice(item.dxlInformation?.price);
    }
  }, [useSimplePrice, item, checkedEditionItem]);

  const fourthEntryPrice = costTwoPrice(item.dxlInformation?.price);
  const priceSuffixTrans = handleTranslation("ex.VAT","sem IVA")

  return (
    <>
      <DetailsAndPricingWrapper color={color} mb={withIncludedBundles || withExtraInformation ? `${rem('16px')}` : 0}>
        <DetailsItem>
          <DetailsTitle color={color}>{firstEntry}</DetailsTitle>
          <DetailsInfo color={color}>{firstEntryValue}</DetailsInfo>
        </DetailsItem>

        <DetailsItem>
          <DetailsTitle color={color}>{secondEntry}</DetailsTitle>
          <DetailsInfo color={color}>{secondEntryValue}</DetailsInfo>
        </DetailsItem>

        <DetailsItem>
          <DetailsTitle color={color}>{thirdEntry}</DetailsTitle>
          {thirdEntryPrice && (
            <DetailsInfo {...(thirdEntryValueColor && { color: color ?? thirdEntryValueColor })}>
              <small>{currencySymbol}</small>
              {thirdEntryPrice}
            </DetailsInfo>
          )}
          <DetailsTitle color={color}>{priceSuffixTrans}</DetailsTitle>
        </DetailsItem>

        {fourthEntry && (
          <DetailsItem>
            <DetailsTitle color={color}>{fourthEntry}</DetailsTitle>
            {fourthEntryPrice && (
              <DetailsInfo color={color}>
                <small>{currencySymbol}</small>
                {fourthEntryPrice}
              </DetailsInfo>
            )}
            <DetailsTitle color={color}>{priceSuffixTrans}</DetailsTitle>
          </DetailsItem>
        )}
      </DetailsAndPricingWrapper>

      {(!!selectedEdition || checkedEditionItem) &&
        item.dxlInformation?.periods &&
        item.dxlInformation?.periods.length > 0 && (
          <DetailsAndPricingWrapper color={color}>
            <RadioButtonList
              fieldWrapper={{
                label: 'Preselected Radio',
                showLabel: false,
              }}
              radioButtons={{
                groupName: 'Preselected',
                id: item.dxlInformation.uuid,
                checked: selectedEdition,
                onChange: handleSelectedEdition,
                items: item.dxlInformation.periods
                  .filter(period => item.editionsPeriodsByUuid?.includes(period.uuid))
                  .map(period => ({
                    label: startCase(`${period.type} contract`.toLowerCase()),
                    value: period.uuid,
                  })),
              }}
            />
          </DetailsAndPricingWrapper>
        )}
    </>
  );
};

export default DetailsAndPricing;

import { useTranslation } from 'react-i18next';
import { withPrefix } from 'gatsby';
import { useMemo } from 'react';

import { useAppSelector, setCurrentStep, useAppDispatch, UserBasketItemProdBundle } from '@/store';
import usingOpcoUrl from '@/helpers/prefixHelper';
import { OPCOOptions } from '@/types/Services.types';

import BasketSummary from '../../Elements/BasketSummary';
import { Unit } from '../../Elements/BasketSummary/BasketSummary.types';
import { SectionBasketSummaryProps } from './SectionBasketSummary.types';
import handleTranslation from '@/helpers/handleTranslation';

const OPCO = process.env.GATSBY_OPCO;

const SectionBasketSummary = ({ basketSummaryUrl, hasNavigationButton = false }: SectionBasketSummaryProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const basketURL = t('basketURL');

  const priceSuffixTrans = handleTranslation('ex.VAT', 'sem IVA');
  const monthlyTrans = handleTranslation('Monthly', 'Mensal');
  const totalCost = handleTranslation('Total cost', 'Valor total');
  const goToBasket = handleTranslation('Go to Basket', 'Finalizar compra');
  const skipToBasket = handleTranslation('Skip to Basket', 'Ir para o carrinho');
  const { currentStep, maxSteps } = useAppSelector(({ userConfigureBundle }) => userConfigureBundle);

  const { data: userBasket } = useAppSelector(({ userBasket }) => userBasket);
  const { user } = useAppSelector(({ userSession }) => userSession);

  const isOpen = useMemo(() => {
    return Boolean(user && userBasket.items.length > 0);
  }, [user, userBasket]);

  const currencySymbol = useMemo(() => {
    if (userBasket?.items.length) {
      const [item] = userBasket.items as UserBasketItemProdBundle[];
      return item.detailAndPricing?.currencySymbol as Unit;
    }
    return undefined;
  }, [userBasket]);

  const handleNextClickButton = () => {
    const nextStep = currentStep + 1;
    if (nextStep <= maxSteps) {
      dispatch(setCurrentStep(nextStep));
    }
  };

  return (
    <BasketSummary
      isOpen={isOpen}
      title={totalCost}
      priceSuffix={priceSuffixTrans}
      monthlyPricePrefix={monthlyTrans}
      upfrontPricePrefix={t('upfront')}
      price={{
        monthly: userBasket.total?.monthly,
        upfront: OPCO !== OPCOOptions.PORTUGAL && userBasket.total?.upfront,
      }}
      buttons={{
        summaryButton: {
          href: basketSummaryUrl ? usingOpcoUrl(withPrefix(basketSummaryUrl)) : usingOpcoUrl(withPrefix(basketURL)),
          text: currentStep < maxSteps ? skipToBasket : goToBasket,
          dataAttributes: {
            'data-selector': 'MS365-BundleConfig-SkipToBasket',
          },
        },
        secondaryButton:
          hasNavigationButton && currentStep < maxSteps
            ? {
                text: t('nextOption'),
                onClick: handleNextClickButton,
                dataAttributes: {
                  'data-selector': 'MS365-BundleConfig-NextOption',
                },
              }
            : undefined,
      }}
      unit={currencySymbol}
    />
  );
};

export default SectionBasketSummary;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { InteractionSlot } from '@legacy-vfuk/core-card-builder';
import { ContainerWithLabel } from '@legacy-vfuk/core-container-with-label';
import { Modal } from '@legacy-vfuk/core-modal';
import { Paragraph } from '@legacy-vfuk/core-paragraph';
import { SimpleGrid } from '@legacy-vfuk/core-simple-grid';
import { TextInput } from '@legacy-vfuk/core-text-input';
import { FC, useState } from 'react';

import { useModalContext } from '@/hooks/modal/useModal';

import { ModalBodyText, MsDomainModalWrapper } from './SWMsDomainModal.styles';
import { SWMsDomainModalProps } from './SWMsDomainModal.types';

const SWMsDomainModal: FC<SWMsDomainModalProps> = ({
  title,
  body,
  subtext,
  inputPlaceholder,
  primaryButton,
  secondaryButton,
}) => {
  const { toggleModal, isMsDomainModalOpen } = useModalContext();
  const [value, setValue] = useState<string>('');

  return (
    <Modal
      srName="MsDomain Modal"
      isOpen={isMsDomainModalOpen}
      size={5}
      animate
      onCloseCb={() => {
        toggleModal(false, 'MsDomain');
      }}
      overlayBlur
      dataSelectorPrefix="ms-domain-modal"
    >
      <MsDomainModalWrapper>
        <ContainerWithLabel
          label={{
            text: `${title}`,
          }}
          level={2}
          elevation={true}
          paddingLevel={4}
        >
          <SimpleGrid
            columns={{
              sm: 1,
            }}
            spacing={5}
            justify="center"
          >
            <ModalBodyText>{documentToReactComponents(JSON.parse(body.raw))}</ModalBodyText>
            <Paragraph size={1}>{subtext}</Paragraph>
            <TextInput
              type="text"
              placeholder={inputPlaceholder}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
            {/* buttons functionalities to be added later  */}
            <InteractionSlot
              appearance="primary"
              primaryButton={{ text: `${primaryButton.label}`, onClick: () => {}, width: 'full' }}
              secondaryButton={{
                text: `${secondaryButton.label}`,
                onClick: () => {},
                width: 'full',
              }}
            />
          </SimpleGrid>
        </ContainerWithLabel>
      </MsDomainModalWrapper>
    </Modal>
  );
};

export default SWMsDomainModal;

import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import tickIcon from '@legacy-vfuk/source-mid-render-light-icons/dist/assets/tick-outline.svg';
import { rem } from 'polished';

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: ${rem('1180px')};
  margin: 0 auto;

  .slick-slide {
    > div {
      height: 100%;
      padding-bottom: ${rem('10px')};
      margin: 0 ${rem('8px')};

      > div {
        max-width: ${rem('345px')};

        @media (min-width: ${props => props.theme.breakpoints.md}px) {
          margin: 0 auto;
          max-width: ${rem('382px')};
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        padding-bottom: 0;
        margin: 0 ${rem('10px')} ${rem('10px')};
      }
    }
  }

  [class*='CarouselArrowstyle__CarouselArrow-sc'] {
    z-index: 4;
    border: ${rem('1px')} solid #979797;
    background: ${({ theme }) => theme.color.primary2.default};
    box-shadow: 0 ${rem('2px')} ${rem('4px')} 0 rgba(0, 0, 0, 0.29);
    margin-top: 0;
    top: ${rem('180px')};
    width: ${rem('50px')};
    height: ${rem('50px')};

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      top: ${rem('150px')};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
      top: ${rem('195px')};
    }

    &[direction='previous'] {
      right: initial;
      left: ${rem('-35px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        left: ${rem('-40px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        left: ${rem('-55px')};
      }
    }
    &[direction='next'] {
      left: initial;
      right: ${rem('-35px')};

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        right: ${rem('-40px')};
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        right: ${rem('-55px')};
      }
    }

    [class*='Interactionstyle__Button-sc'] {
      border: none;
      :hover {
        background: ${({ theme }) => theme.color.primary2.default};
        * {
          stroke: #0d0d0d;
        }
      }

      [class*='Iconstyle__Icon-sc'] {
        svg {
          width: ${rem('30px')};
          height: ${rem('30px')};

          :focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  [class*='FunctionalCarouselstyle__CarouselDotPagerList-sc'] {
    margin-bottom: ${rem('64px')};
    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      margin-bottom: 0;
    }

    li {
      margin: ${rem('32px')} 0;

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin: ${rem('30px')} 0 ${rem('42px')};
      }
    }
  }
`;

export const CarouselCardsWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary2.default};
  display: flex !important;
  flex-direction: column;
  box-shadow: 0 ${rem('2px')} ${rem('8px')} 0 ${({ theme }) => theme.color.opacity4.default};
  margin: auto;

  min-height: ${rem('399px')};
  height: auto;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-height: ${rem('313px')};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-height: ${rem('372px')};
  }
`;

export const AccordionIcon = styled(Box)`
  font-size: ${rem('17px')};
  display: flex;
`;

export const ItemWrapper = styled(Flex)`
  line-height: ${rem('24px')};
  padding-top: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: ${rem('18px')} 0;
    letter-spacing: ${rem('-1.4px')};
    font-size: ${rem('28px')};
    line-height: ${rem('33px')};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
      margin: ${rem('16px')} 0;
      font-size: ${rem('24px')};
      line-height: ${rem('28px')};
      height: ${rem('56px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: ${rem('60px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      margin: ${rem('18px')} 0;
      font-size: ${rem('28px')};
      line-height: ${rem('33px')};
      height: ${rem('66px')};
    }
  }

  ul {
    margin: 0 0 0 ${rem('10px')};
    list-style-image: url(${tickIcon});
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      font-size: ${rem('14px')};
      line-height: ${rem('16px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('18px')};
      line-height: ${rem('24px')};
    }

    li {
      padding-left: 0;
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
        margin: 0 0 0 ${rem('-28px')};
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin: 0 0 0 ${rem('-29px')};
      }

      > p {
        margin: ${rem('-2px')} 0 ${rem('6px')};
      }

      ::marker {
        font-size: ${rem('28px')};
      }
    }

    li:first-of-type {
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        margin-top: 0;
      }
    }
  }

  h4 + span {
    min-height: ${rem('96px')};
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
    theme.breakpoints.lg - 1}px) {
      min-height: ${rem('80px')};
    }
  }

  span {
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
    theme.breakpoints.lg - 1}px) {
      font-size: ${rem('14px')};
      line-height: ${rem('16px')};
    }
  }

  span.sub-heading {
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: ${rem('96px')};
    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}px) {
      font-size: ${rem('18px')};
      line-height: ${rem('20px')};
      -webkit-line-clamp: 5;
      height: ${rem('99.2px')};
    }
    @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
      font-size: ${rem('16px')};
      line-height: ${rem('18px')};
      height: ${rem('89.6px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      font-size: ${rem('18px')};
      line-height: ${rem('24px')};
      -webkit-line-clamp: 4;
      height: ${rem('96px')};
    }
  }

  p.accordion-body {
    font-size: ${rem('18px')};
    line-height: ${rem('24px')};
    padding: 0;
    margin: 0 0 ${rem('15px')};
    overflow: scroll;
    height: ${rem('348px')};
    p {
      margin-bottom: ${rem('5px')};
      font-size: ${rem('18px')};
      line-height: ${rem('24px')};
      @media only screen and (min-width: ${props => props.theme.breakpoints.sm}px) {
        font-size: ${rem('18px')};
        line-height: ${rem('20px')};
      }
      @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
        font-size: ${rem('16px')};
        line-height: ${rem('18px')};
      }
      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        font-size: ${rem('18px')};
        line-height: ${rem('24px')};
      }
    }
  }

  .heading-subheading-block {
    height: ${rem('198px')};
    @media only screen and (min-width: ${props => props.theme.breakpoints.sm}px) {
      height: ${rem('187.2px')};
      padding: 0 ${rem('24px')};
    }
    @media only screen and (min-width: ${props => props.theme.breakpoints.md}px) {
      height: ${rem('181.6px')};
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      height: ${rem('198px')};
    }
  }
`;

import Image from '@legacy-vfuk/core-image';
import axios from 'axios';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { retrieveProduct } from '@/services/product.services';
import { DxlInformation, Product } from '@/types/Product.types';

import { addLinkTestId } from '../../../../helpers/addTestId';
import SWProductDescription from '../../Elements/SWProductDescription/SWProductDescription';
import { Container, WrapImage, Wrapper } from './SWProductDescriptionAndBuy.styles';
import { SWProductDescriptionAndBuyProps } from './SWProductDescriptionAndBuy.types';

const SWProductDescriptionAndBuy = ({
  productTitle,
  productImage,
  productDescriptionCard,
  productBuySection,
}: SWProductDescriptionAndBuyProps) => {
  const [productData, setProductData] = useState<Product['dxlInformation']>();
  const productImageSrc = getSrc(productImage) || getImage(productImage)?.placeholder?.fallback || '';
  const productId = productBuySection?.productId;
  const { setErrorInfo, toggleModal } = useModalContext();

  useEffect(() => {
    (async () => {
      const res = await retrieveProduct(productId);
      if (!axios.isAxiosError(res) && res.status === 200) {
        setProductData(res.data.dxlInformation);
      } else {
        setErrorInfo({
          ...(axios.isAxiosError(res) && {
            statusType: res.response?.data?.error,
            statusCode: res.response?.data?.code || res.response?.status,
          }),
          requestUrl: 'catalogues',
        });
        toggleModal(true, 'Error');
        isEnvDevelopment() && console.error({ error: res });
      }
    })();
  }, [productId]);

  return (
    <Wrapper>
      <Container>
        <WrapImage>
          <Image
            data-testid={addLinkTestId('productDescriptionAndBuyImage', productTitle)}
            alt={productImage.description || ''}
            sm={{
              src: productImageSrc || '',
              width: '100%',
            }}
          />
        </WrapImage>
        <SWProductDescription {...productDescriptionCard} apiData={productData} />
      </Container>
    </Wrapper>
  );
};

export default SWProductDescriptionAndBuy;

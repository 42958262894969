import { useState } from 'react';

import Button from '@legacy-vfuk/core-button';
import Heading from '@legacy-vfuk/core-heading';
import { Icon } from '@uk-source-web/icon';

import Form from '@/components/pageBuilder/Elements/Form';
import { FormModalProps } from '../SWFormModal.types';

import {
  FormContainer,
  FormModalCloseButton,
  SWFormModalCloseBtn,
  SWFormModalHeader,
  SWFormModalHeaderIcon,
  SWFormModalWrapper,
} from './FormModal.styles';

const FormModal = ({ form, buttonSubmitLoading, handleForm, handleSubmitAction }: FormModalProps) => {
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  return (
    <>
      <SWFormModalHeader>
        <SWFormModalHeaderIcon>
          <Icon name="consultant" group="system" inverse size={5} />
        </SWFormModalHeaderIcon>
        <Heading level={3} justify="center">
          {form.title}
        </Heading>
        <SWFormModalCloseBtn>
          <FormModalCloseButton
            color="white"
            sx={{
              svg: {
                boxSize: 4,
              },
            }}
          />
        </SWFormModalCloseBtn>
      </SWFormModalHeader>
      <SWFormModalWrapper>
        {form?.subtitle && (
          <Heading level={4} justify="center">
            {form.subtitle}
          </Heading>
        )}
        <FormContainer>
          <Form
            formInputs={form.formInputs}
            formValidation={isValid => setIsValidForm(isValid)}
            formSubmit={handleForm}
          />
        </FormContainer>
        <Button
          appearance="primary"
          loading={buttonSubmitLoading}
          onClick={handleSubmitAction}
          text={form.submitButton.label}
          state={!isValidForm ? 'disabled' : undefined}
          dataSelectorPrefix={`submitFormButton`}
          href={!form.onSubmitAction ? form.submitButton.url : undefined}
        />
      </SWFormModalWrapper>
    </>
  );
};

export default FormModal;

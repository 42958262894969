import styled from 'styled-components';
import { rem } from 'polished';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  padding: ${rem('20px')};
  margin-bottom: ${rem('12px')};
  border-radius: ${rem('6px')};
  object-fit: cover;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-right: ${rem('12px')};
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: ${rem('6px')};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: ${rem('370px')};
    }
  }

  h4 {
    margin: ${rem('12px')} 0;
  }

  p {
    font-family: 'VodafoneLight';
  }
`;

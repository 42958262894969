import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Image from '@legacy-vfuk/core-image';
import LinkCard from '@legacy-vfuk/core-link-card';

import SimpleGrid from '@legacy-vfuk/core-simple-grid';
import Spacing from '@legacy-vfuk/core-spacing';
import axios from 'axios';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { useEffect, useState } from 'react';

import { lowestEditionPrice } from '@/helpers/findPrices';
import formatCurrency from '@/helpers/formatCurrency';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';
import { useModalContext } from '@/hooks/modal/useModal';
import { TError } from '@/services/error.types';
import { retrieveProduct } from '@/services/product.services';

import { Icon } from '@legacy-vfuk/core-icon';
import PriceTag from '../PriceTag/PriceTag';
import { ShortenedHeader } from './ShortenedHeader';
import {
  FeatureCardImagesAndPriceWrapper,
  FeatureCardProductImageWrapper,
  FeatureCardWrapper,
  HeadingWrap,
  SubHeadWrap,
} from './SWFeatureCard.styles';
import { SWFeatureCardProps } from './SWFeatureCard.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWFeatureCard = ({
  backgroundImage,
  currencySymbol = '€',
  heading,
  pillText,
  richTextBody,
  isPropositionCard,
  primaryButton,
  primaryImage,
  productIcon,
  productId,
  gridLayout = true,
}: SWFeatureCardProps) => {
  const productIconSrc = getSrc(productIcon) ?? getImage(productIcon)?.placeholder?.fallback ?? '';
  const primaryImageSrc = getSrc(primaryImage) ?? getImage(primaryImage)?.placeholder?.fallback ?? '';
  const backgroundImageSrc = getSrc(backgroundImage) ?? getImage(backgroundImage)?.placeholder?.fallback ?? '';
  const [price, setPrice] = useState<string>('XX');
  const { setErrorInfo, toggleModal } = useModalContext();

  const formattedHeading =
    heading.length > 59 ? <ShortenedHeader heading={heading} maxSize={59} hoverText={'...'} /> : heading;

  useEffect(() => {
    (async () => {
      try {
        if (productId?.match('[0-9]{5,6}')) {
          const res = await retrieveProduct(productId);
          if (!axios.isAxiosError(res) && res.status === 200) {
            const productPrice = formatCurrency(
              Number(lowestEditionPrice(res.data.dxlInformation, res.data.contentfulInformation))
            );
            setPrice(productPrice ?? '0.00');
          } else if (axios.isAxiosError(res)) {
            setErrorInfo({
              statusType: res.response?.data?.error,
              statusCode: res.response?.data?.code || res.response?.status,
              requestUrl: 'catalogues',
            });
            toggleModal(true, 'Error');
          }
        }
      } catch (err) {
        const error = err as TError;
        isEnvDevelopment() && console.log(error);
      }
    })();
  }, [productId]);

  return (
    <FeatureCardWrapper
      gridLayout={gridLayout}
      className={isPropositionCard ? 'propositionCard' : ''}
      pill={pillText}
      data-selector="feature card"
    >
      {!isPropositionCard && (
        <FeatureCardImagesAndPriceWrapper>
          <SimpleGrid
            layout={{
              sm: [12, 7, 5],
              md: [12, 7, 5],
              lg: [2, 7, 3],
              xl: [2, 7, 3],
            }}
            spacing={0}
          >
            <Spacing
              spacingLevel={{
                top: 3,
                left: 3,
                bottom: 6,
              }}
            >
              <Image
                alt={''}
                sm={{
                  src: productIconSrc,
                  width: '50px',
                  height: 'auto',
                }}
              />
            </Spacing>
            <Spacing
              spacingLevel={{
                left: 4,
                right: 6,
              }}
            >
              <FeatureCardProductImageWrapper>
                <Image
                  alt={`${heading} product image`}
                  sm={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                      x3: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '140px',
                  }}
                  md={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                      x3: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '100px',
                  }}
                  lg={{
                    src: primaryImageSrc,
                    srcSet: {
                      x1: primaryImageSrc,
                      x2: primaryImageSrc,
                    },
                    width: 'auto',
                    height: '180px',
                  }}
                />
              </FeatureCardProductImageWrapper>
            </Spacing>
            <PriceTag {...{ price, currencySymbol }} />
          </SimpleGrid>
        </FeatureCardImagesAndPriceWrapper>
      )}
      {isPropositionCard ? (
        <FeatureCardProductImageWrapper gridLayout={gridLayout}>
          <Image
            alt={`${heading} product image`}
            sm={{
              src: backgroundImageSrc,
              srcSet: {
                x1: backgroundImageSrc,
                x2: backgroundImageSrc,
                x3: backgroundImageSrc,
              },
              width: 'auto',
              height: '140px',
            }}
            md={{
              src: backgroundImageSrc,
              srcSet: {
                x1: backgroundImageSrc,
                x2: backgroundImageSrc,
                x3: backgroundImageSrc,
              },
              width: 'auto',
              height: '100px',
            }}
            lg={{
              src: backgroundImageSrc,
              srcSet: {
                x1: backgroundImageSrc,
                x2: backgroundImageSrc,
              },
              width: 'auto',
              height: '180px',
            }}
          />
          <HeadingWrap>{formattedHeading}</HeadingWrap>
          <SubHeadWrap>{documentToReactComponents(JSON.parse(richTextBody?.raw))}</SubHeadWrap>
        </FeatureCardProductImageWrapper>
      ) : (
        <LinkCard
          elevation
          appearance="primary"
          image={{
            xl: {
              src: backgroundImageSrc,
            },
            alt: '',
          }}
          headingText={formattedHeading}
          text={
            <div>
              {documentToReactComponents(JSON.parse(richTextBody?.raw))}
              <div
                style={{
                  color: '#bd0000',
                }}
                id="primaryButton"
              >
                <span
                  style={{
                    fontSize: '18px',
                    cursor: 'pointer',
                  }}
                >
                  <a
                    href={usingOpcoUrl(primaryButton?.url)}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    {primaryButton?.label}
                  </a>
                </span>
                <Icon name="chevron-right" justify="right" size={2} appearance="secondary" />
              </div>
            </div>
          }
          {...(pillText && {
            pill: {
              text: pillText,
            },
          })}
        />
      )}
    </FeatureCardWrapper>
  );
};

export default SWFeatureCard;

import React from 'react';
import { StandardBanner as _StandardBanner } from '@uk-source-web/standard-banner';
import { SWSectionStandardBannerProps } from './SWSectionStandardBanner.types';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderToString } from 'react-dom/server';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWSectionStandardBanner = ({
  backgroundColor,
  backgroundImage,
  heading,
  headingLevel,
  footNote,
  contentAlignment,
  contentSize,
  bodyText,
  primaryButton,
}: SWSectionStandardBannerProps) => {
  const backgroundImageSrc = getSrc(backgroundImage) || '' || getImage(backgroundImage)?.placeholder?.fallback || '';
  const richTextBodyJson = bodyText && JSON.parse(bodyText.raw);
  const richTextBodyContent = richTextBodyJson && { ...richTextBodyJson, content: richTextBodyJson.content[0].content };
  return (
    <_StandardBanner
      id={'standard-banner-id'}
      backgroundColor={backgroundColor ? backgroundColor : '#fff'}
      backgroundImage={{
        sm: {
          src: backgroundImageSrc,
        },
        md: {
          src: backgroundImageSrc,
        },
        lg: {
          src: backgroundImageSrc,
        },
      }}
      paragraph={
        bodyText
          ? {
              text: bodyText && renderToString(documentToReactComponents(richTextBodyContent)),
            }
          : undefined
      }
      contentAlignment={contentAlignment ? contentAlignment : 'left'}
      contentSize={contentSize ? contentSize : 1}
      footnote={
        footNote
          ? {
              text: footNote.text,
              link: {
                text: footNote.urlText,
                href: footNote.url,
              },
            }
          : undefined
      }
      heading={{
        text: heading ?? '',
        level: headingLevel ? headingLevel : 2,
      }}
      primaryButton={
        primaryButton
          ? {
              text: primaryButton?.label,
              href: usingOpcoUrl(primaryButton?.url),
              ...(primaryButton.customAttributesJson && {
                htmlAttributes: { ...primaryButton.customAttributesJson },
              }),
            }
          : undefined
      }
    />
  );
};

export default SWSectionStandardBanner;

import Heading from '@legacy-vfuk/core-heading';
import Image from '@legacy-vfuk/core-image';
import SimpleGrid from '@legacy-vfuk/core-simple-grid';
import { getImage, getSrc } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { addLinkTestId } from '../../../../helpers/addTestId';
import SWFilterFlyout from '../../Elements/SWFilterFlyout/SWFilterFlyout';
import SWSectionSearchBar from '../../Elements/SWSectionSearchBar/SWSectionSearchBar';
import SWSectionSelect from '../../Elements/SWSectionSelect/SWSectionSelect';
import * as Styles from './SWSectionSelectAndSearchBar.styles';
import { SWSelectAndSearchBarProps } from './SWSectionSelectAndSearchBar.types';
import { useLocation } from '@reach/router';

const SWSectionSelectAndSearchBar: FC<SWSelectAndSearchBarProps> = (props: SWSelectAndSearchBarProps) => {
  const {
    heading,
    subheading,
    filterFlyout,
    searchBar,
    selectInput,
    mobileBackgroundImage,
    backgroundImage,
    searchHeightClass,
  } = props;
  const mobileBackgroundImageSrc =
    getSrc(mobileBackgroundImage) || getImage(mobileBackgroundImage)?.placeholder?.fallback;
  const backgroundImageSrc = getSrc(backgroundImage) || getImage(backgroundImage)?.placeholder?.fallback;
  const { pathname } = useLocation();
  const isProductListPage = pathname === '/marketplace/product-list';

  return (
    <Styles.SelectAndSearchBarAndImageWrapper>
      <div className={searchHeightClass}>
        {heading ? (
          <>
            <Image
              alt={mobileBackgroundImage?.description || ''}
              sm={{
                src: mobileBackgroundImageSrc || '',
                width: '100%',
              }}
              lg={{
                src: backgroundImageSrc || '',
                width: '100%',
              }}
            />
            <Styles.SelectAndSearchBarWrapper hasOptions={selectInput?.selectOptions}>
              <SimpleGrid
                layout={{
                  sm: [12],
                }}
                justify="center"
                spacing={0}
                id="selectAndSearchBar"
              >
                <Heading
                  level={2}
                  justify={'center'}
                  text={heading}
                  data-testid={addLinkTestId('selectAndSearchBarHeading', heading || '')}
                />
                <Heading
                  level={3}
                  justify={'center'}
                  text={subheading || ''}
                  data-testid={addLinkTestId('selectAndSearchBarSubHeading', subheading || '')}
                />
                {selectInput && <SWSectionSelect {...selectInput} heading={heading} />}
              </SimpleGrid>
            </Styles.SelectAndSearchBarWrapper>
          </>
        ) : (
          <Styles.SelectAndSearchBarWrapper isProductListPage={isProductListPage}>
            <SimpleGrid
              layout={
                isProductListPage
                  ? {
                      sm: 12,
                      md: [0, 5, 0],
                      lg: [0, 3.7, 0],
                      xl: [0, 2, 0],
                    }
                  : {
                      sm: [0, 1, 11, 0],
                      md: [0, 2, 6.75, 3.25],
                      lg: [0, 1, 6, 5],
                    }
              }
              justify={'center'}
              spacing={isProductListPage ? 0 : 2}
            >
              <span></span>
              {filterFlyout && <SWFilterFlyout {...filterFlyout} />}
              {searchBar && <SWSectionSearchBar {...searchBar} />}
              <span></span>
            </SimpleGrid>
          </Styles.SelectAndSearchBarWrapper>
        )}
      </div>
    </Styles.SelectAndSearchBarAndImageWrapper>
  );
};
export default SWSectionSelectAndSearchBar;

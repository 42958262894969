import styled from '@emotion/styled';
import { rem } from 'polished';

interface ThemeProps {
  invertTheme?: boolean;
}

export const CreditVettingWrapper = styled.div<ThemeProps>`
  width: 100%;
  display: flex;
  background-color: ${({ invertTheme }) => (invertTheme ? '#f4f4f4' : '#222')};
  align-items: center;
  justify-content: center;
  color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
  flex-direction: column;
  font-family: 'VodafoneLight';
  height: ${rem('976px')};
`;

export const CVFTitle = styled.div<ThemeProps>`
  display: flex;
  font-size: ${rem('40px')};
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
`;

export const CVFDescription = styled.div<ThemeProps>`
  display: flex;
  font-size: ${rem('20px')};
  justify-content: center;
  align-items: center;
  max-width: 780px;
  text-align: center;
  color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
`;

export const ElementWrapper = styled.div<ThemeProps>`
  width: ${rem('380px')};
  display: flex;
  justify-content: center;
  align-items: center;

  [class*='FieldWrapperstyle__Label'] {
    color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
  }
  [class*='DateTextInputstyle__DateTextInputWrapper-sc'] {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none !important;
  }
  [class*='DateTextInputstyle__DateTextInput'] {
    justify-content: center;
  }
  [class*='DateTextInputWithLabelstyles__DateTextInputWrapper'] {
    width: 100%;
  }
`;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '@legacy-vfuk/core-button';
import { withPrefix } from 'gatsby';
import React from 'react';

import { ButtonWrapper, ContentContainer, ShortBannerContainer, Title, Wrapper } from './SWShortBanner.styles';
import { SWShortBannerProps } from './SWShortBanner.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWShortBanner = ({
  name,
  shortDescription,
  textColor = 'white',
  primaryButton,
  customButton,
}: SWShortBannerProps) => {
  return (
    <Wrapper data-selector="short banner">
      <ShortBannerContainer>
        <ContentContainer>
          {shortDescription && (
            <Title textColor={textColor}>{documentToReactComponents(JSON.parse(shortDescription.raw))}</Title>
          )}
          {(customButton || primaryButton) && (
            <ButtonWrapper>
              {customButton && <div id={customButton} />}
              {primaryButton && (
                <Button
                  text={primaryButton?.label}
                  appearance="secondary"
                  inverse
                  href={usingOpcoUrl(withPrefix(primaryButton?.url))}
                  dataSelectorPrefix={name?.toLowerCase()}
                />
              )}
            </ButtonWrapper>
          )}
        </ContentContainer>
      </ShortBannerContainer>
    </Wrapper>
  );
};

export default SWShortBanner;

import SearchInput from '@legacy-vfuk/core-search-input/dist/';
import { FC } from 'react';

import { SWSearchBarInputProps } from './SWSectionSearchBar.types';
import { useSessionContext } from '@/contexts/UserSessionContext';

const SWSectionSearchBar: FC<SWSearchBarInputProps> = ({
  id,
  placeholder,
  label,
  showLabel,
  width,
}: SWSearchBarInputProps) => {
  const { searchValue, setSearchValue } = useSessionContext();

  return (
    <SearchInput
      textInput={{
        id,
        placeholder,
        value: searchValue,
        onChange: e => setSearchValue(e.target.value),
      }}
      fieldWrapper={{
        label,
        showLabel,
        width,
      }}
      onClear={() => setSearchValue('')}
    />
  );
};

export default SWSectionSearchBar;

import Button from '@legacy-vfuk/core-button';
import Heading from '@legacy-vfuk/core-heading';
import Image from '@legacy-vfuk/core-image';
import { getImage, getSrc } from 'gatsby-plugin-image';

import { Container, FormContainer, VerticalSpacing } from './SWForm.styles';
import { SWFormProps } from './SWForm.types';
import Form from '../../Elements/Form';

const SWForm = ({ title, subtitle, formInputs, submitButton, topImage }: SWFormProps) => {
  const topImageSrc = topImage ? getSrc(topImage) || getImage(topImage)?.placeholder?.fallback : '';

  return (
    <Container>
      {topImageSrc && (
        <Image
          alt={`topImage`}
          sm={{
            src: topImageSrc,
            width: '120px',
            height: '120px',
          }}
        />
      )}
      <VerticalSpacing size={40} />
      <Heading size={4} text={title} />
      {subtitle && <Heading size={2} text={subtitle} />}
      <FormContainer>
        <Form formInputs={formInputs} />
      </FormContainer>
      <Button
        text={submitButton.label}
        appearance="primary"
        href={submitButton.url}
        dataSelectorPrefix={`submitFormButton`}
      />
    </Container>
  );
};

export default SWForm;

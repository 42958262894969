import { Heading } from '@uk-source-web/heading';
import AdditionalLinkCards from '../../Elements/AdditionalLinkCards';
import { AdditionalLinksCards, AdditionalLinksContainer, AdditionalLinksWrapper } from './AdditionalLinks.styles';
import { AdditionalLinksProps } from './AdditionalLinks.types';

const AdditionalLinks = ({ linkCards, title }: AdditionalLinksProps) => {
  return (
    <AdditionalLinksContainer>
      <AdditionalLinksWrapper>
        {title && <Heading level={3}>{title}</Heading>}
        <AdditionalLinksCards>
          {linkCards.map(card => (
            <AdditionalLinkCards {...card} />
          ))}
        </AdditionalLinksCards>
      </AdditionalLinksWrapper>
    </AdditionalLinksContainer>
  );
};

export default AdditionalLinks;

import { FC, useEffect, useState } from 'react';

import { useBoolean } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContainerWithLabel, { ContainerWithLabelProps } from '@legacy-vfuk/core-container-with-label';

import { RichText } from '@/types/Product.types';

import CardRender from './CardRender';
import { SWPricingCardProps } from './SWPricingCard.types';
import {
  CardContainer,
  CardSkeleton,
  CustomiseBundleText,
  PricingCardInner,
  PricingCardWrapper,
  Ribbon,
  RibbonWrapper,
} from './SWPricingCard.styles';

const SWPricingCard: FC<SWPricingCardProps> = ({
  item,
  isLoading,
  itemStepName,
  isMiddleOne,
  mainItem,
  mergedProduct,
  includesCards,
}) => {
  const [toggleStatus, setToggleStatus] = useBoolean(false);
  const [selectedItem, setSelectedItem] = useState(item);

  const { pathname } = useLocation();

  useEffect(() => {
    if (item) {
      if (toggleStatus) {
        //Change edition Toggled On
        setSelectedItem({ ...item, ...item.toggleVersion });
      } else {
        //Change edition Toggled Off
        setSelectedItem(item);
      }
    }
  }, [toggleStatus]);

  useEffect(() => {
    setSelectedItem(item);
  }, [item]);

  const renderCustomiseBundleText = (customiseBundleText: RichText) => (
    <CustomiseBundleText
      textAlign={pathname.includes('configuration') ? 'center' : 'left'}
      sx={{
        p: {
          fontSize: 'sm',
          m: 0,
        },
      }}
    >
      {documentToReactComponents(JSON.parse(customiseBundleText.raw))}
    </CustomiseBundleText>
  );

  const renderItem = () => {
    if (!selectedItem) {
      return <CardSkeleton isLoaded={!isLoading} />;
    } else {
      const {
        id,
        detailAndPricing,
        bundleIncludes,
        customiseBundleText,
        promotionBgColor,
        promotionTextColor,
        promotionText,
      } = selectedItem;

      const shouldChangeTheme = isMiddleOne && bundleIncludes ? bundleIncludes?.length > 0 : false;

      const containerLabelRaw = detailAndPricing?.containerLabel?.raw ?? '';
      const containerLabel = containerLabelRaw ? JSON.parse(containerLabelRaw) : null;

      const containerProps: Partial<ContainerWithLabelProps> = {};

      if (
        detailAndPricing?.containerLabel?.raw &&
        detailAndPricing?.containerAppearance &&
        detailAndPricing?.containerAppearance !== 'none'
      ) {
        if (!pathname.includes('product-list')) {
          containerProps.label = documentToReactComponents(JSON.parse(detailAndPricing.containerLabel.raw)) as string;
          containerProps.appearance = detailAndPricing.containerAppearance;
        }

        containerProps.level = 1;
        containerProps.label = {
          text: containerLabel?.content[0].content[0].value,
        };
      } else {
        containerProps.showOuterTopPadding = true;
      }

      return (
        <>
          <PricingCardInner
            key={id}
            containerAppearance={detailAndPricing?.containerAppearance}
            isMiddleOne={shouldChangeTheme}
          >
            <ContainerWithLabel {...containerProps}>
              <CardContainer>
                {promotionText && (
                  <RibbonWrapper promotionBgColor={promotionBgColor}>
                    <Ribbon promotionTextColor={promotionTextColor}>{promotionText}</Ribbon>
                  </RibbonWrapper>
                )}
                <CardRender
                  {...{
                    pathname,
                    item: selectedItem,
                    mergedProduct,
                    itemStepName,
                    isMiddleOne,
                    includesCards,
                    toggleStatus,
                    setToggleStatus,
                    mainItem,
                  }}
                />
              </CardContainer>
            </ContainerWithLabel>
          </PricingCardInner>

          {customiseBundleText && !pathname.includes('product-list') && renderCustomiseBundleText(customiseBundleText)}
        </>
      );
    }
  };

  return (
    <PricingCardWrapper w={!selectedItem ? 'full' : 'auto'} h={selectedItem?.notificationStatus ? 'auto' : 'full'}>
      {renderItem()}
    </PricingCardWrapper>
  );
};

export default SWPricingCard;

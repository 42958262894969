import Container from '@/components/Global/Container';
import { rem } from 'polished';
import styled from 'styled-components';

export const AdditionalLinksContainer = styled(Container)``;

export const AdditionalLinksWrapper = styled.div`
  padding: ${rem('56px')} 0;
  h3 {
    font-family: 'VodafoneLight';
    margin-bottom: ${rem('40px')};
  }
`;

export const AdditionalLinksCards = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: row;
  }
`;

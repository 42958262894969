import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Button } from '@uk-source-web/button';
import { Heading } from '@uk-source-web/heading';
import { Icon } from '@uk-source-web/icon';

import { OrderCompletedPurchaseProps } from './OrderCompletedPurchase.types';

import CompletedPurchaseStyles, {
  CalloutMessage,
  CompletedPurchaseButtons,
  CompletedPurchaseCallout,
  CompletedPurchaseContainer,
  CompletedPurchaseContent,
  CompletedPurchaseHeader,
} from './OrderCompletedPurchase.styles';
import { useLocation } from '@reach/router';

const OrderCompletedPurchase = ({
  title,
  purchaseStatus,
  messageHeading,
  mainMessage,
  calloutMessages,
  vodafoneButton,
  continueButton,
}: OrderCompletedPurchaseProps) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const orderId = queryParams.get('id') || 'teste@email.com';
  const userEmail = queryParams.get('email') || '0800';

  const formatMainMessage = (msg: OrderCompletedPurchaseProps['mainMessage']) => {
    let message = msg.raw;
    message = message.replace('${orderId}', orderId);
    message = message.replace('${userEmail}', userEmail);

    return documentToReactComponents(JSON.parse(message));
  };

  return (
    <CompletedPurchaseStyles>
      <CompletedPurchaseContainer>
        <CompletedPurchaseHeader>
          <Icon name={purchaseStatus} group="state" />
          <Heading level={3}>{title}</Heading>
        </CompletedPurchaseHeader>
        <CompletedPurchaseContent>
          <Heading level={5} weight={4}>
            {messageHeading}
          </Heading>
          <CompletedPurchaseCallout>
            <Icon name={purchaseStatus} group="state" size={3} />
            <CalloutMessage>{formatMainMessage(mainMessage)}</CalloutMessage>
          </CompletedPurchaseCallout>
          {calloutMessages?.map(callout => (
            <CompletedPurchaseCallout key={callout.id}>
              {callout?.icon && <Icon name={callout.icon} group="system" size={3} />}
              <CalloutMessage>{documentToReactComponents(JSON.parse(callout.message.raw))}</CalloutMessage>
            </CompletedPurchaseCallout>
          ))}
        </CompletedPurchaseContent>
        <CompletedPurchaseButtons>
          <Button
            id="completed-purchase-vodafone"
            text={vodafoneButton.label}
            href={vodafoneButton?.url}
            icon={vodafoneButton?.iconRef ? { name: vodafoneButton.iconRef } : undefined}
            appearance="primary"
          />
          <Button
            id="completed-purchase-continue"
            text={continueButton.label}
            href={continueButton?.url}
            icon={continueButton?.iconRef ? { name: continueButton.iconRef } : undefined}
            appearance="alt1"
          />
        </CompletedPurchaseButtons>
      </CompletedPurchaseContainer>
    </CompletedPurchaseStyles>
  );
};

export default OrderCompletedPurchase;

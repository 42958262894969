import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';

import MainLayout from '@/layouts/MainLayout';

import { ProductsTemplateProps } from './products.types';
import RenderContent from './renderContent';

const Products = ({ pageContext }: PageProps<ProductsTemplateProps, ProductsTemplateProps>) => {
  const { pageData } = pageContext;
  const { sections, seoDescription, seoTitle } = pageData;

  return (
    <MainLayout>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
      </Helmet>

      {sections?.map(section => (
        <RenderContent key={section.id} section={section} />
      ))}
    </MainLayout>
  );
};

export default Products;

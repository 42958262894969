import { TextInput } from '@legacy-vfuk/core-text-input';
import { GroupedTextFieldset, TextInputWrapper } from './GroupedTextInput.styles';
import { DirectionKeys, GroupedTextInputProps } from './GroupedTextInput.types';

const GroupedTextInput = ({ type = 'text', id, values, onChange, onBlur, textInputs }: GroupedTextInputProps) => {
  const getTextInputId = (inputIndex: number): string => {
    return `${id}-${inputIndex}`;
  };

  const goToInputAtIndex = (index: number): void => {
    const input = document.getElementById(getTextInputId(index)) as HTMLInputElement;
    if (input) {
      input.select();
    }
  };

  // Handles where the focus is between the inputs
  const handleFocus = (index: number, direction: DirectionKeys): void => {
    const nextIndex = direction === 'left' ? index - 1 : index + 1;

    if (values.length !== nextIndex) {
      goToInputAtIndex(nextIndex);
    }
  };

  // Handles when there has been a change to the input
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, inputIndex: number): void => {
    const newValues = [...values];

    if (event.target.value.length === textInputs.maxLength) {
      handleFocus(inputIndex, 'right');
    }

    if (onChange) {
      newValues[inputIndex] = event.target.value;
      onChange(newValues);
    }
  };

  const handleOnBlur = (): void => {
    // Has to be in a setTimeout to ensure the focus is in the correct place
    setTimeout(() => {
      // Check if any of the inputs are currently focused
      const activeIndex = values.findIndex((value, index) => {
        const input = document.getElementById(getTextInputId(index)) as HTMLInputElement;
        return document.activeElement === input;
      });

      // If none are focused and there is a blur function then call it
      if (onBlur && activeIndex === -1) {
        onBlur(values);
      }
    });
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, inputIndex: number): void => {
    if (textInputs.onKeyDown) textInputs.onKeyDown(event);

    if (event.key !== 'Backspace') return;
    // If it's the first input, do nothing
    if (inputIndex === 0) return;
    // If the input isn't empty, backspace as normal
    if (values[inputIndex].length > 0) return;

    event.preventDefault();
    handleFocus(inputIndex, 'left');
  };

  return (
    <GroupedTextFieldset onBlur={handleOnBlur}>
      {values.map((value, inputIndex) => {
        const textInputId = getTextInputId(inputIndex);
        return (
          <TextInputWrapper key={`text-input-wrapper-${inputIndex}`}>
            <TextInput
              id={textInputId}
              type={type}
              value={value}
              maxLength={textInputs.maxLength}
              onKeyDown={(event): void => {
                handleOnKeyDown(event, inputIndex);
              }}
              onChange={(event): void => {
                handleOnChange(event, inputIndex);
              }}
            />
          </TextInputWrapper>
        );
      })}
    </GroupedTextFieldset>
  );
};

export default GroupedTextInput;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getImage, getSrc } from 'gatsby-plugin-image';

import { Heading } from '@uk-source-web/heading';
import { Span } from '@uk-source-web/span';
import { Icon } from '@uk-source-web/icon';
import Image from '@legacy-vfuk/core-image';

import {
  UserBasketItem,
  UserBasketItemBase,
  isInBasket,
  removeFromBasket,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { OPCOOptions } from '@/types/Services.types';

import BasketPrice from '../BasketPrice';
import BasketItemDelete from '../BasketItemDelete';
import BasketItemBundle from '../BasketItemBundle';
import { BasketItemProps } from '../SWBasket.types';
import BasketQuantitySelector from '../BasketQuantitySelector';
import { NULL_PRICE_VALUE, getBundleIncludes, getMonthlyPrice, getUpfrontPrice } from '../SWBasket.helper';

import BasketItemStyles, {
  BasketAccordion,
  BasketAccordionButton,
  BasketAccordionButtonWrapper,
  BasketAccordionItem,
  BasketAccordionPanel,
  BasketAccordionPanelWrapper,
  BasketItemContents,
  BasketItemFlag,
  BasketItemGridPrices,
  BasketItemInner,
  BasketItemThumb,
  BasketItemThumbWrapper,
  BasketItemTitle,
} from './BasketItem.styles';
import handleTranslation from '@/helpers/handleTranslation';

const OPCO = process.env.GATSBY_OPCO;

const BasketItem = (item: BasketItemProps) => {
  const { increaseDecreaseAct = true, quantity } = item;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userBasket = useAppSelector(state => state.userBasket.data);
  const currentItem: UserBasketItem | undefined = userBasket.items.filter(
    currentItem => currentItem.productId === item?.productId
  )[0];

  const bundleIncludes = getBundleIncludes(item);
  const hasBundleIncludes = bundleIncludes?.length > 0;
  const hasUpfrontPrice = OPCO === OPCOOptions.IRELAND;

  const thumbnailIconSrc =
    typeof item.thumbnailIcon == 'string'
      ? item.thumbnailIcon
      : getSrc(item.thumbnailIcon) ?? getImage(item.thumbnailIcon)?.placeholder?.fallback;

  const upFrontPrice = useMemo(() => getUpfrontPrice(currentItem, quantity), [currentItem]);
  const monthlyPrice = useMemo(() => getMonthlyPrice(currentItem), [currentItem]);

  const currentFlag = item.configTab?.toLowerCase().includes('bundle') ? 'Bundle' : item.configTab;
  const quantityTrans = handleTranslation("Quantity", "Quantidade")
  const monthlyTrans = handleTranslation("Monthly", "Mensal")

  const handleRemoveItem = () => {
    if (isInBasket(userBasket, item)) {
      dispatch(removeFromBasket(item));
    }
  };

  return (
    <BasketItemStyles hasFlag={!!currentFlag}>
      <BasketItemThumbWrapper>
        <BasketItemThumb hasThumbnail={!!thumbnailIconSrc}>
          <Image alt="image" sm={{ src: thumbnailIconSrc ?? '' }} />
        </BasketItemThumb>
      </BasketItemThumbWrapper>
      <BasketAccordion allowMultiple allowToggle defaultIndex={[0]}>
        <BasketAccordionItem>
          {({ isExpanded }) => (
            <BasketItemInner>
              <BasketItemTitle>
                <Heading level={4} weight={3} text={item?.dxlInformation?.name ?? item?.name} />
                {hasBundleIncludes && (
                  <BasketAccordionButtonWrapper isExpanded={isExpanded}>
                    <BasketAccordionButton>
                      <Span id="basket-accordion-details">{t('seeDetails')}</Span>
                      <Icon appearance="secondary" group="system" name="chevron-down" id="basket-accordion-button" />
                    </BasketAccordionButton>
                  </BasketAccordionButtonWrapper>
                )}
              </BasketItemTitle>
              <BasketItemContents>
                <BasketQuantitySelector
                  item={{ increaseDecreaseAct, quantity, ...currentItem }}
                  label={quantityTrans}
                />
                <BasketItemGridPrices hasUpfrontPrice={hasUpfrontPrice}>
                  {hasUpfrontPrice && (
                    <BasketPrice label={t('upfront')} value={checkForZeroOrNull(upFrontPrice, NULL_PRICE_VALUE)} />
                  )}
                  <BasketPrice
                    label={monthlyTrans}
                    value={checkForZeroOrNull(monthlyPrice * (quantity || 1), NULL_PRICE_VALUE)}
                  />
                </BasketItemGridPrices>
                <BasketItemDelete handleDelete={handleRemoveItem} />
              </BasketItemContents>
              {hasBundleIncludes && (
                <BasketAccordionPanelWrapper>
                  <BasketAccordionPanel>
                    <BasketItemBundle bundle={bundleIncludes} />
                  </BasketAccordionPanel>
                </BasketAccordionPanelWrapper>
              )}
            </BasketItemInner>
          )}
        </BasketAccordionItem>
      </BasketAccordion>
      {!!currentFlag && <BasketItemFlag>{currentFlag}</BasketItemFlag>}
    </BasketItemStyles>
  );
};

export default BasketItem;

import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { Span } from '@uk-source-web/span';

const BasketPriceStyles = styled.div`
  padding: ${rem('5px')};
  display: flex;
  flex-direction: column;

  h4,
  span {
    color: var(--mineShaft);
  }
`;

export const LabelPrice = styled(Span)<{ labelAlign?: 'left' | 'right' | 'center' }>`
  font-family: 'VodafoneLight';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }

  ${({ labelAlign }) => {
    switch (labelAlign) {
      case 'left':
        return css`
          text-align: left;
        `;
      case 'right':
        return css`
          text-align: right;
        `;
      case 'center':
        return css`
          text-align: center;
        `;
    }
  }}
`;

export const HeadingPrice = styled.div`
  display: flex;

  > h4 {
    font-size: ${rem('32px')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    > h4 {
      font-size: ${rem('32px')};
    }
  }
`;

export const CurrencyPrice = styled.span`
  font-size: ${rem('20px')};
`;

export const SuffixPrice = styled.span`
  font-family: 'VodafoneLight';
  margin-left: ${rem('2px')};
  font-size: ${rem('16px')};
  font-weight: 200;
`;

export default BasketPriceStyles;

import { rem } from 'polished';

import IconStepper, { IconStepperProps } from '@legacy-vfuk/core-icon-stepper';

import {
  HeadingSubtitleProps,
  HeadingText,
  HeadingTitleProps,
  HeadingWrapper,
  NavigationWrapper,
  StepWrapper,
  StepperBox,
  StepperWrapper,
} from './ConfigureSteps.styles';

interface ConfigureStepsProps {
  title: string;
  countStep?: number;
  currentStep: number;
  disableSteps?: boolean;
  steps: IconStepperProps['steps'];
  buttonDestinationLink?: string;
  handleBackClickButton?: () => void;
  handleNextClickButton?: () => void;
}

const ConfigureSteps = ({ title, disableSteps, steps, currentStep }: ConfigureStepsProps) => (
  <StepWrapper>
    <HeadingWrapper textAlign="center">
      <HeadingText as="h2" {...HeadingTitleProps}>
        Configure the Bundle
      </HeadingText>

      <HeadingText as="h3" {...HeadingSubtitleProps}>
        {title}
      </HeadingText>
    </HeadingWrapper>

    <NavigationWrapper
      direction={{ base: 'column', md: 'row' }}
      justify="center"
      mt={disableSteps ? { base: 8, md: `${rem('-84px')}` } : { md: rem('50px'), lg: 12 }}
    >
      {!disableSteps && (
        <StepperWrapper stepsLength={steps.length}>
          <StepperBox w={'full'} mt={{ base: rem('42px'), md: 0 }} mb={{ base: rem('46px'), md: 0 }}>
            {steps.length > 0 && <IconStepper currentStep={currentStep} steps={steps} hideControls />}
          </StepperBox>
        </StepperWrapper>
      )}
    </NavigationWrapper>
  </StepWrapper>
);

export default ConfigureSteps;

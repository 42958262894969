import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';
import { generateDays, generateYears, months } from '../SelectOptions';
import { GetAddressType } from '@/services/address.services';

export const contactDetailsFormInputs: IFormInput[] = [
  {
    inputType: 'inputField',
    label: 'Name',
    name: 'firstName',
    fullWidth: false,
    lengthValidationSize: 2,
    placeholder: 'First name',
    requiredField: true,
    validationType: 'length',
    labelColor: '#FFF',
    dataType: 'alpha',
  },
  {
    inputType: 'inputField',
    name: 'lastName',
    label: 'Last Name',
    fullWidth: false,
    lengthValidationSize: 2,
    placeholder: 'Last name',
    requiredField: true,
    validationType: 'length',
    labelType: 'transparent',
    dataType: 'alpha',
  },
  {
    inputType: 'select',
    name: 'yearSelect',
    label: 'Date of birth',
    fullWidth: false,
    placeholder: 'Year',
    requiredField: true,
    labelColor: '#FFF',
    customWidth: '33%',
    customValue: 'Year',
    options: generateYears(1950, 2006),
  },
  {
    inputType: 'select',
    name: 'monthSelect',
    label: 'month',
    fullWidth: false,
    placeholder: 'Month',
    requiredField: true,
    labelType: 'transparent',
    customWidth: '33%',
    customValue: 'Month',
    options: months,
  },
  {
    inputType: 'select',
    name: 'daySelect',
    label: 'day',
    fullWidth: false,
    placeholder: 'Day',
    requiredField: true,
    customValue: 'Day',
    options: generateDays(1, 2006),
    labelType: 'transparent',
    customWidth: '33%',
  },
  {
    inputType: 'inputField',
    name: 'contactPhone',
    label: 'Contact Phone',
    fullWidth: true,
    placeholder: 'Enter your number',
    requiredField: true,
    validationType: 'phone',
    phoneValidationCode: '087',
    lengthValidationSize: 10,
    labelColor: '#FFF',
  },
  {
    inputType: 'inputField',
    name: 'emailAddress',
    label: 'Email address',
    fullWidth: true,
    placeholder: 'Enter your email',
    requiredField: true,
    validationType: 'email',
    labelColor: '#FFF',
    helpText: 'This email will be used to login to My Vodafone.',
    dataType: 'email',
  },
  {
    inputType: 'radioList',
    name: 'AddressOption',
    label: 'Address or Eircode search option',
    fullWidth: true,
    labelColor: '#FFF',
    radioOptions: [
      { label: 'address', value: 'TEXT' as GetAddressType },
      { label: 'Eircode', value: 'POSTAL_CODE' as GetAddressType },
    ],
  },
  {
    inputType: 'inputField',
    name: 'address',
    label: 'Address',
    fullWidth: true,
    disabled: true,
    labelType: 'hidden',
    placeholder: 'Leopardstown 13, Dublin',
    validationType: 'length',
    dataType: 'alphanum',
  },
  {
    inputType: 'checkbox',
    name: 'addressCheckbox',
    label: 'Enter your address manually',
    fullWidth: true,
    labelType: 'hidden',
    validationType: 'length',
    checkboxOptions: [{ label: 'Enter your address manually', value: 'addressManually' }],
  },
  {
    inputType: 'inputField',
    name: 'postcode',
    label: 'Eircode',
    fullWidth: false,
    requiredField: true,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    dataType: 'alphanum',
    // disabled: true,
  },
  {
    inputType: 'inputField',
    name: 'address1',
    label: 'Address 1',
    requiredField: true,
    fullWidth: false,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    // disabled: true,
  },
  {
    inputType: 'inputField',
    name: 'address2',
    label: 'Address 2',
    fullWidth: false,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
  },
  {
    inputType: 'inputField',
    name: 'city',
    label: 'City',
    fullWidth: false,
    placeholder: '',
    customWidth: '33%',
    requiredField: true,
    labelColor: '#FFF',
  },
  {
    inputType: 'select',
    name: 'stateOrProvince',
    label: 'County',
    fullWidth: false,
    placeholder: '',
    customWidth: '33%',
    requiredField: true,
    labelColor: '#FFF',
  },
  {
    inputType: 'select',
    name: 'countryCode',
    label: 'Country',
    fullWidth: false,
    placeholder: '',
    customValue: 'IE',
    customWidth: '33%',
    labelColor: '#FFF',
    options: [{ text: 'Ireland', value: 'IE' }],
  },
  {
    inputType: 'radioList',
    name: 'Choose login type',
    label: 'Choose login type',
    fullWidth: true,
    requiredField: true,
    labelColor: '#FFF',
    radioOptions: [
      { label: 'via email', value: 'email' },
      { label: 'via mobile', value: 'mobile' },
    ],
  },
];

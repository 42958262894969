import Heading from '@legacy-vfuk/core-heading';
import Icon from '@legacy-vfuk/core-icon';
import { FC } from 'react';

import { IconAndTextWrapper } from './SWFooterIconAndText.styles';
import { ISWFooterIconAndTextItem } from './SWFooterIconAndTextItem.types';

const SWFooterIconAndTextItem: FC<ISWFooterIconAndTextItem> = ({
  iconName,
  title,
  iconGroup,
  color,
  redirectUrl,
  iconSize,
}) => {
  return (
    <IconAndTextWrapper
      redirectUrl={redirectUrl}
      onClick={() => {
        window.open(redirectUrl);
      }}
      iconSize={iconSize}
    >
      <Icon group={iconGroup} name={iconName} inverse={color === 'white'} size={2} isResponsive={false} />
      <Heading level={4} text={title} inverse={color === 'white'} />
    </IconAndTextWrapper>
  );
};

export default SWFooterIconAndTextItem;

import { SlotWrapperStyles } from '@legacy-vfuk/core-card-builder';
import { ContainerWithLabelStyles } from '@legacy-vfuk/core-container-with-label';
import { SimpleGridStyles } from '@legacy-vfuk/core-simple-grid';
import { TextInputStyles } from '@legacy-vfuk/core-text-input';
import { rem } from 'polished';
import styled from 'styled-components';

export const MsDomainModalWrapper = styled.div`
  ${ContainerWithLabelStyles.ContainerWithLabel} {
    max-width: ${rem('380px')};
    height: ${rem('392px')};
    margin: 0 auto;

    > *:first-child {
      height: auto;
      div:first-child {
        padding: ${rem('10px')} 0;
        span {
          margin: 0 auto;
          width: ${rem('250px')};
          white-space: normal;
          line-height: ${rem('26px')};
          font-weight: 700;

          font-size: ${rem('24px')};
          text-align: center;
          letter-spacing: ${rem('0.5px')};
        }
      }
    }

    > *:last-child {
      padding: ${rem('13px')} ${rem('20px')} ${rem('20px')};
      > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      ::after {
        border-color: transparent;
      }
    }

    ${SimpleGridStyles.SimpleGrid} {
      flex-grow: 1;
      align-content: space-between;
      ${SimpleGridStyles.SimpleGridColumn} {
        :nth-of-type(2) {
          p {
            text-align: center;
            line-height: ${rem('16px')};
            padding: ${rem('10px')} ${rem('10px')} 0;
          }
        }
        ${TextInputStyles.TextInputWrapper} {
          margin-top: ${rem('-20px')};
          ${TextInputStyles.TextInput} {
            text-align: center;
            border-style: none none solid;
            border-radius: 0;
            padding: 0 ${rem('12px')};
            ::placeholder {
              text-align: center;
              font-size: ${rem('14px')};
            }
          }
        }
      }
    }

    ${SlotWrapperStyles.SlotWrapper} {
      padding: 0;
    }
  }
`;

export const ModalBodyText = styled.div`
  p {
    margin: 0;
  }
`;

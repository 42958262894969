import { rem } from 'polished';
import styled from 'styled-components';

type StandardBannerWrapper = {
  textColor?: string;
  pageLevel?: boolean;
  noSpacing?: boolean;
};

export const StandardBannerWrapper = styled.div<StandardBannerWrapper>`
  position: relative;
  overflow: hidden;
  [class*='StandardBannerstyle__FullWidthContainer-sc'] {
    position: inherit;
    background: rgba(0, 0, 0, 0);
    max-width: ${rem('1180px')};
    margin: 0 auto;
    display: block;
    padding: 0;
    height: ${({ pageLevel }) => (pageLevel ? rem('500px') : rem('360px'))};

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
      height: auto;
      padding-top: ${rem('30px')};
      padding-bottom: ${rem('60px')};
    }

    [class*='StandardBannerstyle__StandardBanner-sc'] {
      [class*='SimpleGridstyle__SimpleGrid-sc'] {
        margin: 0;

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
          flex-direction: column;
        }

        @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          flex-direction: row;
        }

        [class*='SimpleGridstyle__SimpleGridColumn-sc'] {
          margin: 0;

          @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
  theme.breakpoints.lg}px) {
            width: 60%;
          }

          > [class*='SimpleGridstyle__SimpleGrid-sc'] {
            display: block;

            [class*='ContentWrapperstyle__ContentWrapper-sc'] {
              align-items: flex-start;
              width: ${({ noSpacing }) => (noSpacing ? rem('900px') : rem('685px'))};
              padding: ${rem('30px')} 0 0 ${rem('8px')};
              position: relative;
              z-index: 1;
              
              @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                max-width: ${rem('320px')};
                padding: ${rem('32px')} 0 0 ${rem('15px')};
              }
              
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {

                height: ${({ pageLevel }) => (pageLevel ? rem('500px') : 'auto')};  
                gap: ${({ noSpacing }) => noSpacing && rem('0px')};              

              }
              
              
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                height: ${({ pageLevel }) => (pageLevel ? rem('500px') : rem('360px'))};                
              }
              
              @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
  theme,
}) => theme.breakpoints.lg}px) {
                width: ${rem('400px')} !important;
                padding-left: ${rem('16px')} !important;
              }

              h1 {
                color: ${({ textColor }) => (textColor ? `${textColor}` : 'black')};
                padding: 0;
                text-align: left;
                font-weight: bold;
                font-size: ${({ pageLevel }) => (pageLevel ? rem('54px') : rem('44px'))};
                line-height: ${rem('40px')};
                margin-bottom: ${rem('12px')};
                
                @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                  font-size: ${rem('32px')};
                  margin-bottom: ${rem('3px')};
                }
                
                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
  theme,
}) => theme.breakpoints.lg - 1}px) {
                  width: auto;
                  font-size: ${({ pageLevel }) => (pageLevel ? rem('48px') : rem('38px'))};
                  line-height: ${rem('55px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                  line-height: ${({ noSpacing }) => (noSpacing ? '' : rem('65px'))};
                  margin-bottom: ${({ noSpacing }) => (noSpacing ? '' : rem('10px'))};
                }
              }

              p {
                font-size: ${({ pageLevel }) => (pageLevel ? rem('34px') : rem('24px'))};
                line-height: ${rem('29px')};
                color: ${({ textColor }) => (textColor ? `${textColor}` : 'black')};
                padding: 0;
                text-align: left;

                @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
                  width: auto;
                  margin-top: ${rem('-16px')};
                  font-size: ${({ pageLevel }) => (pageLevel ? rem('20px') : rem('10px'))};
                  line-height: ${rem('28px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
                  width: 75%;
                  font-size: ${({ pageLevel }) => (pageLevel ? rem('18px') : rem('12px'))};

                  font-size: ${({ noSpacing }) => noSpacing && rem('28px')} !important;
                  font-weight: ${({ noSpacing }) => noSpacing && '400'};

                  line-height: ${rem('18px')};
                }

                @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
                  font-size: ${({ pageLevel }) => (pageLevel ? rem('38px') : rem('28px'))};
                  line-height: ${rem('35px')};
\                }
              }
            }
          }
        }

        [class*='ImageContainerstyle__BackgroundWrapper-sc'] {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: auto;

          [class*='ImageContainerstyle__BackgroundImage-sc'] {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
          }
        }
      }
    }
  }

  [class*='redCircularGradient'] {
    background: radial-gradient(circle, rgba(130, 0, 0, 1) 0%, rgba(130, 0, 0, 1) 17%, rgba(166, 0, 0, 1) 100%);
    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
  theme.breakpoints.lg - 1}px) {
      [class*='ContentWrapperstyle__ContentWrapper-sc'] {
        width: ${rem('400px')} !important;
        padding-left: ${rem('16px')} !important;
      }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      [class*='ContentWrapperstyle__ContentWrapper-sc'] {
        height: auto;
      }
    }

    [class*='ImageContainerstyle__BackgroundWrapper-sc'] {
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
        height: -webkit-fill-available;
        bottom: ${rem('40px')};

        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 50% 83% !important;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({ theme }) =>
  theme.breakpoints.lg - 1}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: right 50% !important;
          background-size: 54%;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) and (max-width: ${({ theme }) =>
  theme.breakpoints.xl - 1}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 100% 50% !important;
          background-size: 50%;
        }
      }

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
        [class*='ImageContainerstyle__BackgroundImage-sc'] {
          background-position: 75% 50% !important;
          background-size: contain !important;
        }
      }
    }
  }
`;

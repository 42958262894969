import axios, { AxiosError } from 'axios';

import { UserBasketItem, UserSessionProps } from '@/store';
import { ContentfulInformation, DxlInformation, DxlInformationPrice, Feature, Product } from '@/types/Product.types';

import { api } from './auth.services';
import { InitialState, ModalTypes } from '@/hooks/modal/types';

export type ProductOrderBasketProduct = {
  productUuid: DxlInformation['uuid'];
  qty: UserBasketItem['quantity'];
  unit: DxlInformationPrice['unit'];
};

export type ProductOrderBasket = Pick<UserSessionProps, 'organizationId' | 'userId'> & {
  products: ProductOrderBasketProduct[];
};

export type ProductOrderProps = {
  basket: ProductOrderBasket;
};

export type CreditVettingPayload = {
  bankStartDay: string;
  businessStartDate: string;
};

type ProductOrderResponse = {
  userEmail: string;
  id: string;
  needCreditVetting?: boolean;
};

type RetrieveProductProps = Pick<Product, 'id' | 'dxlInformation'> & {
  contentfulInformation?: ContentfulInformation;
};

export const retrieveProduct = async (productId: string) => {
  console.log('Starting a request to retrieve products: ', `/product/${productId}`);
  const res = await api.get<RetrieveProductProps>(`/product/${productId}`).catch<AxiosError>(error => error);

  if (!axios.isAxiosError(res)) {
    console.log(
      `/product/${productId}`,
      ' The request was successfully completed with the following status: ',
      res.request?.status
    );
  } else {
    console.log(
      `/product/${productId}`,
      ' The request was completed unsuccessfully with the following status: ',
      res.request?.status,
      res?.code
    );
  }

  return res;
};

export const productOrder = async (
  productOrder: ProductOrderProps,
  csrfToken: string,
  setErrorInfo: (info: InitialState['errorInfo']) => void,
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
) => {
  try {
    const res = await api.post<ProductOrderResponse>('/product-order', productOrder, {
      headers: { Authorization: csrfToken },
    });
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorInfo({
        statusType: error.response?.data?.error,
        statusCode: error.response?.data?.code || error.response?.status,
        requestUrl: 'product-order',
      });
      toggleModal(true, 'Error');
    }
  }
};

export const retrieveProductFeatures = async (productId: string) => {
  const res = await api.get<Feature[]>(`/product/${productId}/features`).catch<AxiosError>(error => error);
  return res;
};

export const creditVettingProductOrder = async (
  creditVettingData: CreditVettingPayload,
  setErrorInfo: (info: InitialState['errorInfo']) => void,
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
) => {
  try {
    const res = await api.post<{ id: string; userEmail: string }>('/credit-vetting-product-order', creditVettingData);
    return res;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorInfo({
        statusType: error.response?.data?.error,
        statusCode: error.response?.data?.code || error.response?.status,
        requestUrl: 'product-order',
      });
      toggleModal(true, 'Error');
    }

    return false;
  }
};

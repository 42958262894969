import { Text, useBreakpointValue } from '@chakra-ui/react';
import { Heading } from '@legacy-vfuk/core-heading';
import { Icon } from '@legacy-vfuk/core-icon';
import SimpleGrid from '@legacy-vfuk/core-simple-grid';
import Spacing from '@legacy-vfuk/core-spacing';
import type { SpacingKeys } from '@legacy-vfuk/core-themes';
import { rem } from 'polished';
import { FC } from 'react';

import { SWFeatureOverviewCardProps } from './SWFeatureOverviewCard.types';

const SWFeatureOverviewCard: FC<SWFeatureOverviewCardProps> = ({ title, iconName, isWhiteIcons, description }) => {
  const textLeftSpacing = useBreakpointValue(
    { sm: 3, xl: 7 },
    {
      fallback: 'sm',
    }
  );

  return (
    <SimpleGrid
      layout={{
        md: [1, 11],
        sm: [12, 12],
      }}
    >
      <Spacing spacingLevel={{}}>
        <Icon
          name={iconName}
          group={isWhiteIcons ? 'system' : 'midLight'}
          appearance={isWhiteIcons ? 'primary' : 'secondary'}
          size={5}
          inverse={isWhiteIcons}
        />
      </Spacing>
      <Spacing spacingLevel={{ left: textLeftSpacing as SpacingKeys }}>
        <Heading level={3} text={title} size={2} />
        <Text
          fontSize={{ base: 'md', md: 'lg' }}
          lineHeight={rem('18px')}
          mb={0}
          mt={{ base: rem('5px'), md: 0, sm: 0 }}
        >
          {description}
        </Text>
      </Spacing>
    </SimpleGrid>
  );
};

export default SWFeatureOverviewCard;

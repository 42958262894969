import styled from '@emotion/styled';
import { rem } from 'polished';

export const RegisterAccountWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #222;
  z-index: 30;
  position: fixed;
  top: 0;
  padding: ${rem('80px')} 0 0 0;
  color: white;
  display: flex;
  justify-content: center;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-width: ${rem('600px')};
  width: 100%;
  height: fit-content;
  align-items: center;
  flex-direction: column;
`;

export const RegisterTitle = styled.div`
  font-size: ${rem('56px')};
  height: fit-content;
  line-height: normal;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const Spacer = styled.div<{ h?: number; w?: number }>`
  height: ${props => props.h || 0}px;
  width: ${props => props.w || 0}px;
`;

export const ButtonWrapper = styled.div`
  margin: ${rem('30px')} 0;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

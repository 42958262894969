import React from 'react';
import { BannerCarousel  } from '@uk-source-web/banner-carousel';
import { SWBannerCarouselProps } from './SWBannerCarousel.types';
import { BannerCarouselStyles, StandardBannerContainer } from './SWBannerCarousel.styles';
import SWSectionStandardBanner from '../SWSectionStandardBanner';


 
const SWBannerCarousel = ({ srName, waitTime, banners }: SWBannerCarouselProps) => {
 
  const bannerItems = banners
    ? banners.map((item: any) => {
        return {
          title: item?.title || "",
          child: (
            <StandardBannerContainer>
            <SWSectionStandardBanner  key={item?.banner?.id} {...item?.banner} />
            </StandardBannerContainer>
          ),
        };
      })
    : [];
 
  return (
    <BannerCarouselStyles>
    <BannerCarousel
      id="banner-carousel"
      srName={srName ? srName : 'Banner Carousel'}
      waitTime={waitTime  ? waitTime * 1000 : 3000}
      banners={bannerItems}
    />
    </BannerCarouselStyles>
  );
};
 
export default SWBannerCarousel;
 
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getImage, getSrc } from 'gatsby-plugin-image';

import Image from '@legacy-vfuk/core-image';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';
import { LinkWithIcon } from '@legacy-vfuk/core-link-with-icon';

import { CardWrapper } from './AdditionalLinkCards.styles';
import { AdditionalLinkCardsProps } from './AdditionalLinkCards.types';

const AdditionalLinkCards = ({ heading, text, cardImage, linkButton }: AdditionalLinkCardsProps) => {
  const cardImageSrc = getSrc(cardImage) ?? getImage(cardImage)?.placeholder?.fallback ?? '';
  return (
    <CardWrapper>
      <Image
        alt={''}
        sm={{
          src: cardImageSrc,
          width: '100%',
          height: 'auto',
        }}
      />
      <Heading level={4} weight={3} justify="center">
        {heading}
      </Heading>
      {text && <Paragraph>{documentToReactComponents(JSON.parse(text.raw))}</Paragraph>}
      <LinkWithIcon
        appearance="primary"
        icon={{ name: 'chevron-right' }}
        text={linkButton.label}
        href={linkButton.url}
      />
    </CardWrapper>
  );
};

export default AdditionalLinkCards;

import Button from '@legacy-vfuk/core-button';
import { HeadingInfoSlot, HeadingSlot, ImageSlot, ParagraphSlot, TextStackSlot } from '@legacy-vfuk/core-card-builder';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC, useMemo, useState } from 'react';

import InfoAndCta from '../SWPricingCard/CardElements/InfoAndCta';
import {
  CardInfoContainer,
  CenteredDiv,
  FCWrapper,
  FlippedWrapper,
  ImageAndTextListContainer,
  ImageAndTextWrapper,
  ImageListContainer,
  IncludeContentContainer,
  IncludesContainer,
  TopContainerDiv,
} from './SWLargePricingCard.styles';
import { SWLargePricingCardProps } from './SWLargePricingCard.types';
import { ProductBundle } from '@/types/Product.types';
import { ImageDataLikeProps } from '../../Sections/SWPricingContent/SWPricingContent.types';
import { CurrencySymbol } from '@uk-source-web/text-stack';
import handleTranslation from '@/helpers/handleTranslation';

const SWLargePricingCard: FC<SWLargePricingCardProps> = ({ item, itemStepName, mergedProduct, mainItem }) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const includes = handleTranslation('Includes','Incluído')
  const exVat = handleTranslation('ex VAT','sem IVA')

  const thirdEntryPrice = useMemo(() => {
    if (item) {
      return item.dxlInformation?.price[0].price;
    }
  }, [item]);

  if (!item) return <div></div>;



  const thumbnailIconSrc = getSrc(item.thumbnailIcon) || getImage(item.thumbnailIcon)?.placeholder?.fallback || '';
  const parsedIcons =
    item.cardItems?.map(iconItem => {
      return {
        icon: getSrc(iconItem.itemIcon) ?? getImage(iconItem.itemIcon)?.placeholder?.fallback ?? '',
        title: iconItem.itemDescription,
      };
    }) ?? [];

  return (
    <FCWrapper isFlipped={isFlipped}>
      {isFlipped ? (
        <FlippedWrapper isFlipped={isFlipped}>
          <CardInfoContainer>
            <TopContainer {...{ item }} thumbnailIcon={thumbnailIconSrc} />
            <IncludesContainer height="621px">
              <ImageAndTextListContainer>
                {parsedIcons.map((iconItem, index) => {
                  return (
                    <ImageAndTextWrapper key={iconItem.title}>
                      <ImageSlot
                        alt={`${iconItem.title} icon`}
                        xl={{
                          src: iconItem.icon,
                          width: '34px',
                          height: '34px',
                        }}
                        aspectRatio="16/9"
                        objectFit="cover"
                        slotWrapper={{ appearance: 'primary', fullWidth: true }}
                      />
                      <ParagraphSlot paragraph={{ children: iconItem.title, size: 2 }} />
                    </ImageAndTextWrapper>
                  );
                })}
              </ImageAndTextListContainer>
            </IncludesContainer>
            <CenteredDiv>
              {item.dxlInformation && (
                <InfoAndCta
                  {...{ mainItem, itemStepName }}
                  item={{ ...item }}
                  buttonStyling={{}}
                  useSimplePrice={mergedProduct}
                />
              )}
            </CenteredDiv>
          </CardInfoContainer>
        </FlippedWrapper>
      ) : (
        <>
         {thumbnailIconSrc && (
          <TopContainer item={item} thumbnailIcon={thumbnailIconSrc} />
         )}
          <CardInfoContainer>
            <ParagraphSlot paragraph={{ children: item.cardDescription }} />
            <CardInfoContainer flexDirection="row">
              <HeadingInfoSlot
                heading={{ text: item.detailAndPricing?.firstEntryValue ?? '' }}
                size={1}
                prefix={item.detailAndPricing?.firstEntry}
              />
              <HeadingInfoSlot
                heading={{ text: item.detailAndPricing?.secondEntryValue ?? '' }}
                size={1}
                prefix={item.detailAndPricing?.secondEntry}
              />
            </CardInfoContainer>
            <ParagraphSlot paragraph={{ children: item.detailAndPricing?.thirdEntry, size: 1 }} />
            <TextStackSlot
              size={2}
              primaryTextStack={{
                currency: {
                  symbol: (item.detailAndPricing?.currencySymbol as CurrencySymbol) ?? '€',
                  justify: 'left',
                },
                text: {
                  main: thirdEntryPrice?.toString() ?? '00,00',
                  suffix2: exVat,
                },
              }}
            />
          </CardInfoContainer>
          <IncludesContainer height="345px">
            <IncludeContentContainer>
              <HeadingSlot heading={{ text: includes }} size={1} />
              <ImageListContainer>
                {parsedIcons.map(iconItem => {
                  return (
                    <ImageSlot
                      key={iconItem.title}
                      alt={`${iconItem.title} icon`}
                      xl={{
                        src: iconItem.icon,
                        width: '48px',
                        height: '48px',
                      }}
                      aspectRatio="16/9"
                      objectFit="cover"
                      slotWrapper={{ appearance: 'primary', fullWidth: true }}
                    />
                  );
                })}
              </ImageListContainer>
            </IncludeContentContainer>
          </IncludesContainer>
          {item.dxlInformation && (
            <InfoAndCta
              {...{ mainItem, itemStepName }}
              item={{ ...item }}
              buttonStyling={{}}
              useSimplePrice={mergedProduct}
            />
          )}
        </>
      )}
    </FCWrapper>
  );
};

const TopContainer = (item: ProductBundle, thumbnailIcon: ImageDataLikeProps) => (
  <TopContainerDiv>
    <ImageSlot
      alt={`${item?.item?.name} icon`}
      xl={{
        src: item?.thumbnailIcon,
        width: '64px',
        height: '64px',
      }}
      aspectRatio="16/9"
      objectFit="cover"
      slotWrapper={{ appearance: 'primary', fullWidth: true }}
    />
    <HeadingSlot heading={{ text: item?.item?.name }} />
  </TopContainerDiv>
);

export default SWLargePricingCard;

import { Button, ButtonProps } from '@uk-source-web/button';
import { SmallButton } from '@uk-source-web/small-button';
import { useBreakpoints } from '@uk-source-web/match-media';

import { BasketNavigationWrapper } from './BasketNavigation.styles';
import { BasketNavigationButtons } from '../BasketSummary.types';

type ButtonTypes = 'summary' | 'secondary';

const BasketNavigation = ({ summaryButton, secondaryButton }: BasketNavigationButtons) => {
  const { sm, mdAndAbove } = useBreakpoints();

  const getDefaultText = (type: ButtonTypes) => {
    if (type === 'summary') {
      return 'Continue';
    } else if (type === 'secondary') {
      return 'Next option';
    }
    return '';
  };

  const getButtonsProps = (
    btn: BasketNavigationButtons['summaryButton'] | BasketNavigationButtons['secondaryButton'],
    type: ButtonTypes
  ): ButtonProps => ({
    customRouterProps: btn?.customRouterProps,
    href: btn?.href,
    onClick: btn?.onClick,
    text: btn?.text ? btn.text : getDefaultText(type),
    state: btn?.state ? 'disabled' : undefined,
    loading: btn?.loading,
  });

  const summaryBtnProps = getButtonsProps(summaryButton, 'summary');
  const secondaryBtnProps = secondaryButton && getButtonsProps(secondaryButton, 'secondary');

  return (
    <BasketNavigationWrapper>
      {sm ? <SmallButton {...summaryBtnProps} /> : <Button {...summaryBtnProps} width={mdAndAbove ? 'auto' : 'full'} />}
      {secondaryButton &&
        secondaryBtnProps &&
        (sm ? (
          <SmallButton {...secondaryBtnProps} appearance="secondary" />
        ) : (
          <Button {...secondaryBtnProps} width={mdAndAbove ? 'auto' : 'full'} appearance="secondary" />
        ))}
    </BasketNavigationWrapper>
  );
};

export default BasketNavigation;

import Image from '@legacy-vfuk/core-image';
import LoadingSpinner from '@legacy-vfuk/core-loading-spinner';
import Paragraph from '@legacy-vfuk/core-paragraph';
import Toggle from '@legacy-vfuk/core-toggle';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { FC, useEffect, useState } from 'react';
import { useModalContext } from '@/hooks/modal/useModal';
import DetailsAndPricing from '../../CardElements/DetailsAndPricing';
import ExtraInformation from '../../CardElements/ExtraInformation';
import InfoAndCta from '../../CardElements/InfoAndCta';
import BundleIncludes from '../../CardElements/BundleIncludes';
import { CardHeading, ImageWrap, LoadingWrapper, WrapToggle } from '../../SWPricingCard.styles';
import { SWStandardCardProps } from './SWStandardCard.types';
import {
  StandardCardHeader,
  StandardCardHeaderWrapper,
  StandardCardTitle,
  StandardCardTitleWrapper,
} from './SWStandardCard.styles';
import { SWItemProduct } from '../../SWPricingCard.types';
import axios from 'axios';
import { retrieveProduct } from '@/services/product.services';
import { useTranslation } from 'react-i18next';
import handleTranslation from '@/helpers/handleTranslation';

 
const SWStandardCard: FC<SWStandardCardProps> = ({
  pathname,
  mainItem,
  mergedProduct,
  itemStepName,
  shouldChangeTheme,
  toggleStatus,
  setToggleStatus,
  selectedEditionItem,
  checkedEditionItem,
  item,
  handleSelectedEdition,
}) => {
 
  const [card, setCard] = useState(item?.toggleDescription ? item : JSON.parse(JSON.stringify(item)));
 
  const { bundleIncludes, addOn, thumbnailIcon, enableToggleOption, extraInformation, toggleDescription,includeTitle } =
    card as SWItemProduct;
  const { setErrorInfo, toggleModal } = useModalContext();
 
 
  useEffect(() => {
    const fetchDxlInformation = async () => {
      const newProduct = await retrieveProduct(item.productId);
      if (!axios.isAxiosError(newProduct) && newProduct.status === 200 && newProduct.data && newProduct.data.id) {
        setCard((prev: SWItemProduct) => ({ ...prev, ...newProduct.data.dxlInformation }));
      } else {
        setErrorInfo({
          ...(axios.isAxiosError(newProduct) && {
            statusType: newProduct.response?.data?.error,
            statusCode: newProduct.response?.data?.code || newProduct.response?.status,
          }),
          requestUrl: 'catalogues',
        });
        toggleModal(true, 'Error');
      }
    };
 
 
    !card?.dxlInformation && fetchDxlInformation();
  }, []);
 
  const thumbnailIconSrc = getSrc(thumbnailIcon) ?? getImage(thumbnailIcon)?.placeholder?.fallback ?? '';
  return (
    <>
      <StandardCardHeaderWrapper className="cardHeaderElements">
 
        {card?.dxlInformation ? (
 
          <CardHeading px={5}>
            <StandardCardHeader direction="row" spacing={5}>
              {thumbnailIconSrc && (
                <ImageWrap>
                  <Image
                    alt={thumbnailIcon?.title ?? ''}
                    sm={{
                      src: thumbnailIconSrc,
                      width: '48px',
                      height: '48px',
                    }}
                    md={{
                      src: thumbnailIconSrc,
                      width: '64px',
                      height: '64px',
                    }}
                  />
                </ImageWrap>
              )}
              <StandardCardTitleWrapper>
                <StandardCardTitle as="h2" color={shouldChangeTheme ? 'white' : 'mineShaft'}>
 
                  {card?.dxlInformation.name}
 
                </StandardCardTitle>
              </StandardCardTitleWrapper>
            </StandardCardHeader>
          </CardHeading>
        ) : (
          <LoadingWrapper>
            <LoadingSpinner appearance="primary" size={5} />
          </LoadingWrapper>
        )}
 
        {card?.dxlInformation && card.detailAndPricing && (
 
          <>
            <Paragraph size={2} weight={1} justify="left">
              {card?.shortDescription?.shortDescription}
            </Paragraph>
            <DetailsAndPricing
              itemStepName={itemStepName}
              item={card}
              color={shouldChangeTheme ? 'white' : undefined}
              useSimplePrice={mergedProduct}
              selectedEdition={selectedEditionItem}
              checkedEditionItem={checkedEditionItem}
              handleSelectedEdition={handleSelectedEdition}
            />
          </>
        )}
        {enableToggleOption && !pathname.includes('configuration') && (
          <WrapToggle toggleStatus={toggleStatus}>
            <Toggle
              id="align-left-toggle"
              name="align-left-toggle"
              value="toggle-11"
              align="left"
              isActive={toggleStatus}
              onChange={() => setToggleStatus.toggle()}
 
              dataSelectorPrefix={item?.dxlInformation?.name.toLowerCase()}
 
            >
              {mergedProduct ? (
                <span>
                  PREMIUM SUBSCRIPTION <small>{toggleDescription}</small>
                </span>
              ) : (
                <span>
                  OPT OUT OF SECURITY <small>Remove security assessment from bundle</small>
                </span>
              )}
            </Toggle>
          </WrapToggle>
        )}
      </StandardCardHeaderWrapper>
      {bundleIncludes && (
        <BundleIncludes
          title={handleTranslation("Includes","Incluído")}
          items={bundleIncludes}
          backgroundColor={shouldChangeTheme ? '#8bc6d0' : undefined}
        />
      )}
      {!pathname.includes('configuration') && addOn && (
        <BundleIncludes
          title="Optional Add-On"
          items={addOn}
          backgroundColor={shouldChangeTheme ? '#00697C' : 'white'}
          color={shouldChangeTheme ? 'white' : undefined}
        />
      )}
      {extraInformation && <ExtraInformation extraInformation={extraInformation} />}
 
      {card?.dxlInformation && (
 
        <InfoAndCta
          {...{ mainItem, itemStepName }}
          item={{ ...item, selectedEdition: selectedEditionItem }}
          buttonStyling={{
            backgroundColor: shouldChangeTheme ? 'white' : undefined,
            textColor: shouldChangeTheme ? 'black' : undefined,
          }}
          useSimplePrice={mergedProduct}
        />
      )}
    </>
  );
};
 
export default SWStandardCard;
import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';
import { GetAddressType } from '@/services/address.services';

export const companyDetailsFormConfig: IFormInput[] = [
  {
    inputType: 'select',
    label: 'Company size',
    name: 'companySize',
    placeholder: 'Company Size',
    requiredField: true,
    fullWidth: false,
    labelColor: '#FFF',
    customValue: 'SOHO',
    options: [],
  },
  {
    inputType: 'inputField',
    name: 'companyName',
    label: 'Company name',
    fullWidth: false,
    requiredField: true,
    lengthValidationSize: 2,
    placeholder: 'Company name',
    validationType: 'length',
    labelColor: '#FFF',
  },
  {
    inputType: 'select',
    label: 'Industry Type',
    name: 'industryType',
    placeholder: 'Industry Type',
    fullWidth: false,
    labelColor: '#FFF',
    customValue: 'teste',
    options: [],
  },
  {
    inputType: 'select',
    label: 'Type of Business',
    placeholder: 'Type of Business',
    name: 'typeOfBusiness',
    fullWidth: false,
    requiredField: true,
    customValue: 'limitedCompany',
    labelColor: '#FFF',
    options: [],
  },
  {
    inputType: 'inputField',
    name: 'companyRegistrationNumber',
    label: 'Company Registration number',
    fullWidth: true,
    requiredField: false,
    hidden: true,
    validationType: 'length',
    placeholder: 'Registration number',
    labelColor: '#FFF',
  },
  {
    inputType: 'radioList',
    name: 'AddressOption',
    label: 'Address or Eircode search option',
    fullWidth: true,
    labelColor: '#FFF',
    radioOptions: [
      { label: 'address', value: 'TEXT' as GetAddressType },
      { label: 'Eircode', value: 'POSTAL_CODE' as GetAddressType },
    ],
    disabled: false,
  },
  {
    inputType: 'inputField',
    name: 'address',
    label: 'Address',
    fullWidth: true,
    labelType: 'hidden',
    placeholder: 'Leopardstown 13, Dublin',
    validationType: 'length',
    dataType: 'alphanum',
    disabled: false,
  },
  {
    inputType: 'checkbox',
    name: 'addressCheckbox',
    label: 'Enter your address manually',
    fullWidth: true,
    labelType: 'hidden',
    validationType: 'length',
    checkboxOptions: [
      { label: 'Same as contact address', value: 'sameAsContact' },
      { label: 'Enter your address manually', value: 'addressManually' },
    ],
  },
  {
    inputType: 'inputField',
    name: 'postcode',
    label: 'Eircode',
    fullWidth: false,
    requiredField: true,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    dataType: 'alphanum',
  },
  {
    inputType: 'inputField',
    name: 'address1',
    label: 'Address 1',
    fullWidth: false,
    requiredField: true,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    // disabled: true,
  },
  {
    inputType: 'inputField',
    name: 'address2',
    label: 'Address 2',
    fullWidth: false,
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    // disabled: true,
  },
  {
    inputType: 'inputField',
    name: 'city',
    label: 'City',
    fullWidth: false,
    requiredField: true,

    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    // disabled: true,
  },
  {
    inputType: 'select',
    name: 'stateOrProvince',
    label: 'County',
    fullWidth: false,
    requiredField: true,

    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    // disabled: true,
  },
  {
    inputType: 'select',
    name: 'countryCode',
    label: 'Country',
    fullWidth: false,
    customValue: 'IE',
    placeholder: '',
    customWidth: '33%',
    labelColor: '#FFF',
    options: [{ text: 'Ireland', value: 'IE' }],
  },
];

export const billingDetailsForm: IFormInput[] = [
  {
    inputType: 'inputField',
    name: 'iban',
    label: 'IBAN',
    fullWidth: false,
    placeholder: 'IE29 AIBK 9311 5212 3456 78',
    validationType: 'length',
    labelColor: '#FFF',
    helpText: 'ex: IE29 AIBK 9311 5212 3456 78E',
  },
  {
    inputType: 'inputField',
    name: 'bankName',
    label: 'Bank Name',
    fullWidth: false,
    placeholder: 'Bank Name',
    validationType: 'length',
    labelColor: '#FFF',
  },
];

import { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Button } from '@uk-source-web/button';
import { Icon } from '@uk-source-web/icon';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';

import GroupedTextInput from '@/components/maac-ui/GroupedTextInput/GroupedTextInput';
import { TwoFactorAuthProps } from './SectionTwoFactorAuth.types';
import TwoFactorAuthStyles, {
  GroupedTextWrapper,
  HelperTextWrapper,
  ResendButton,
  TwoFactorAuthText,
  TwoFactorAuthWrapper,
} from './SectionTwoFactorAuth.styles';

const SectionTwoFactorAuth = ({
  title,
  text,
  pinLabel,
  pinHelperText,
  pinResendText,
  resendTimer,
  iconName,
  iconType,
  verifyButton,
}: TwoFactorAuthProps) => {
  const [values, setValues] = useState(['', '', '', '', '']);
  const [countdown, setCountdown] = useState(resendTimer);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const formatText = (contentfulText: TwoFactorAuthProps['text']) => {
    // It will be removed once it is integrated with the API
    const userPhoneNumber = '+35386*****58';

    let text = contentfulText.raw;
    text = text.replace('${userPhoneNumber}', userPhoneNumber);

    return documentToReactComponents(JSON.parse(text));
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const handleAuthentication = () => {
    if (allInputsAreFilled()) {
      // Will be implemented/integrated in a future task
    }
  };

  const resendPIN = () => {
    if (countdown === 0) {
      // Will be implemented/integrated in a future task
    }
  };

  const allInputsAreFilled = () => {
    const unfilled = values.filter(value => !value || value === '');

    if (unfilled.length > 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  return (
    <TwoFactorAuthStyles>
      <TwoFactorAuthWrapper>
        <Icon name={iconName} group={iconType} />
        <Heading level={2} size={2} justify="center">
          {title}
        </Heading>
        <TwoFactorAuthText>{formatText(text)}</TwoFactorAuthText>
        <div>
          <Paragraph>{pinLabel}</Paragraph>
          <GroupedTextWrapper>
            <GroupedTextInput
              id="two-factor-auth-input-grouped"
              values={values}
              type="password"
              textInputs={{ maxLength: 1 }}
              onChange={setValues}
            />
          </GroupedTextWrapper>
          <HelperTextWrapper>
            <Paragraph>{pinHelperText}</Paragraph>
            <ResendButton type="button" onClick={resendPIN} disabled={isResendDisabled}>
              {pinResendText}
            </ResendButton>
            {isResendDisabled && <Paragraph>({formatTime(countdown)})</Paragraph>}
          </HelperTextWrapper>
        </div>
        <Button
          id="completed-purchase-continue"
          text={verifyButton.label}
          appearance="primary"
          width="full"
          onClick={handleAuthentication}
          state={!allInputsAreFilled() ? 'disabled' : undefined}
          data-selector="TwoFactorAuth-VerifyButton"
        />
      </TwoFactorAuthWrapper>
    </TwoFactorAuthStyles>
  );
};

export default SectionTwoFactorAuth;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContentBlockWithVideo from '@legacy-vfuk/core-content-block-with-video';
import { FC } from 'react';
import ContentfulRichText from '@uk-source-web/contentful-rich-text';

import SWSectionFiftyFiftyBanner from '../SWSectionFiftyFiftyBanner';
import { WrapItem, Wrapper, WrapText } from './SWContentBlockWithVideo.styles';
import { SWContentBlockWithVideoGroupProps, SWContentBlockWithVideoProps } from './SWContentBlockWithVideo.types';

const SWContentBlockWithVideo: FC<SWContentBlockWithVideoGroupProps> = ({
  id,
  __typename,
  contentBody,
  contentPosition,
  items,
  videoPosition,
  isPropositionVideo,
  isVerticalLayout,
  backgroundColor,
  banners,
  ...props
}: SWContentBlockWithVideoGroupProps) => {
  const renderContent = () => {
    if (items?.length > 0) {
      return items?.map(
        ({
          id,
          __typename,
          name,
          headingLevel,
          contentBody,
          contentPosition,
          videoPosition,
          ...item
        }: SWContentBlockWithVideoProps) => (
          <WrapItem key={`${__typename}-${id}`} position={videoPosition} textAlign={contentPosition}>
            <ContentBlockWithVideo
              key={`${__typename}-${id}`}
              heading={{
                ...(headingLevel && { level: headingLevel }),
                text: name,
              }}
              content={{
                bodyText: contentBody ? documentToReactComponents(JSON.parse(contentBody.raw)) : '',
                contentPosition: contentPosition || 'left',
              }}
              {...{ videoPosition }}
              {...item}
            />
          </WrapItem>
        )
      );
    } else if (!items && props.videoId) {
      return (
        <WrapItem
          key={`${__typename}-${id}`}
          position={videoPosition}
          textAlign={contentPosition}
          isVerticalLayout={isVerticalLayout}
          isPropositionVideo={isPropositionVideo}
        >
          <ContentBlockWithVideo
            key={`${__typename}-${id}`}
            content={{
              bodyText: contentBody ? documentToReactComponents(JSON.parse(contentBody.raw)) : '',
              contentPosition: contentPosition || 'left',
            }}
            {...{ videoPosition }}
            {...props}
          />
        </WrapItem>
      );
    } else if (!items && !props.videoId && contentBody) {
      return (
        <WrapText>
          <ContentfulRichText document={JSON.parse(contentBody.raw)} />
        </WrapText>
      );
    } else {
      return '';
    }
  };

  return (
    <Wrapper
      {...(!items && { position: videoPosition, isPropositionVideo, isVerticalLayout })}
      backgroundColor={backgroundColor}
      contentPosition={contentPosition}
    >
      {renderContent()}
      {banners && banners.map(banner => <SWSectionFiftyFiftyBanner key={banner.id} {...banner} />)}
    </Wrapper>
  );
};

export default SWContentBlockWithVideo;

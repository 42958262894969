import React, { useEffect, useState } from 'react';
import {
  AllSpecsWrapper,
  Container,
  FeaturesRow,
  HPRowContainer,
  HeaderRow,
  HorizontalDivider,
  IconWrapper,
  RowContainer,
  RowHeaderContainer,
  RowIcon,
  RowSpecsContainer,
  RowTitle,
  RowTitleContainer,
  TableCaptionContainer,
  TableElementContainer,
  ContentContainer,
  TopContainer,
} from './SWComparisonTable.styles';
import { ProductHeader } from './ProductHeader';
import Icon from '@legacy-vfuk/core-icon';
import Toggle from '@legacy-vfuk/core-toggle';
import { Feature, SubFeature, ProductBundle } from '@/types/Product.types';
import isEnvDevelopment from '@/helpers/isEnvDevelopment';

import { retrieveProduct, retrieveProductFeatures } from '@/services/product.services';
import { useModalContext } from '@/hooks/modal/useModal';
import axios from 'axios';
import { SWComparisonTableProps, ExpandedState } from './SWComparisonTable.types';
import { TError } from '@/services/error.types';

const isEditionIncluded = (subFeature: SubFeature, editionId: string) => {
  return subFeature.editions.includes(editionId);
};

export const SWComparisonTable: React.FC<SWComparisonTableProps> = ({
  productId,
  editionsToCompare = [],
  shouldFetchData,
}) => {
  const { setErrorInfo, toggleModal } = useModalContext();

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [toggleValue, setToggleValue] = useState<boolean>(false);
  const [features, setFeatures] = useState<Feature[] | []>([]);
  const [editions, setEditions] = useState<ProductBundle[]>([]);

  const fetchProduct = (product: ProductBundle): Promise<ProductBundle> => new Promise(resolve => resolve(product));

  useEffect(() => {
    try {
      const productsIds = editionsToCompare.map(product => product.productId);
      if (shouldFetchData) {
        const productsWithEditions = editionsToCompare.map(async product => {
          product.dxlInformation = undefined;
          if (productsIds.includes(product.productId)) {
            const newProduct = await retrieveProduct(product.productId);
            if (!axios.isAxiosError(newProduct) && newProduct.status === 200 && newProduct.data && newProduct.data.id) {
              product.dxlInformation = newProduct.data.dxlInformation;
            } else {
              setErrorInfo({
                ...(axios.isAxiosError(newProduct) && {
                  statusType: newProduct.response?.data?.error,
                  statusCode: newProduct.response?.data?.code,
                }),
                requestUrl: 'catalogues',
              });
              toggleModal(true, 'Error');
            }
            return product;
          }
        });
        fetchAllProducts(productsWithEditions);
      } else {
        fetchAllProducts(editionsToCompare);
      }
    } catch (err) {
      const error = err as TError;
      isEnvDevelopment() && console.log(error?.response?.data.error);
    }
  }, [editionsToCompare]);

  const fetchAllProducts = async (toFetchProducts: ProductBundle[] | Promise<ProductBundle | undefined>[]) => {
    if (toFetchProducts && toFetchProducts.length > 0) {
      await Promise.all(
        toFetchProducts.map(async (product, index) => {
          const productBundle = await fetchProduct(product as ProductBundle);
          return productBundle;
        })
      )
        .then(productInfo => {
          setEditions(productInfo);
        })
        .catch(error => {
          isEnvDevelopment() && console.log('Error: ', error.message);
        });
    }
  };

  const toggleExpand = (key: string) => {
    setExpanded(prevExpanded => ({
      ...prevExpanded,
      [key]: !prevExpanded[key],
    }));
  };

  const getFeatures = async (prdId: string) => {
    const res = await retrieveProductFeatures(prdId);
    if (!axios.isAxiosError(res) && res.status === 200) {
      setFeatures(res.data);
    } else {
      setErrorInfo({
        ...(axios.isAxiosError(res) && {
          statusType: res.response?.data?.error,
          statusCode: res.response?.data?.code,
        }),
        requestUrl: 'product-order',
      });
      toggleModal(true, 'Error');
    }
  };

  useEffect(() => {
    getFeatures(productId);
  }, [productId]);

  const toggleAll = () => {
    setExpanded(() => {
      const newExpandedState = {} as ExpandedState;

      features.forEach(feature => {
        newExpandedState[feature.id] = !toggleValue;
      });

      return newExpandedState;
    });
    setToggleValue(!toggleValue);
  };

  const getCustomHeight = (title: string) => {
    if (title.length < 30) {
      return '85px';
    }
    if (title.length < 40) {
      return '150px';
    } else {
      return '180px';
    }
  };

  return (
    <Container>
      <ContentContainer>
        <TableCaptionContainer absolutePosition>
          <Caption toggleAll={toggleAll} toggleValue={toggleValue} />
        </TableCaptionContainer>
        <EditionsHeader sticky editions={editions} />
        <FeaturesRow>
          {features.map(feature => (
            <RowContainer key={feature.name} onClick={() => toggleExpand(feature.id)}>
              <RowHeaderContainer>
                <RowTitleContainer>
                  <RowIcon>
                    <Icon
                      name={expanded[feature.id] ? 'chevron-down' : 'chevron-right'}
                      group="system"
                      appearance={'primary'}
                      size={4}
                    />
                  </RowIcon>
                  <RowTitle customHeight={getCustomHeight(feature.name)}>{feature.name}</RowTitle>
                </RowTitleContainer>

                {expanded[feature.id] &&
                  feature.subFeatures.map((subItem, index) => (
                    <>
                      <RowTitle subSpec customHeight={getCustomHeight(subItem.name)}>
                        {subItem.name}
                      </RowTitle>
                      {index !== feature.subFeatures.length - 1 && <HorizontalDivider />}
                    </>
                  ))}
              </RowHeaderContainer>
              <AllSpecsWrapper>
                <RowSpecsContainer customHeight={getCustomHeight(feature.name)}>
                  {!expanded[feature.id] &&
                    editions &&
                    editions.map(edition => (
                      <IncludedIcon feature={feature} key={edition.contentful_id} edition={edition} />
                    ))}
                </RowSpecsContainer>
                {expanded[feature.id] &&
                  feature.subFeatures.map((subFeature, index) => (
                    <>
                      {index === 0 && <HorizontalDivider invisible />}
                      <RowSpecsContainer customHeight={getCustomHeight(subFeature.name)}>
                        {editions?.map(edition => {
                          if (!isEditionIncluded(subFeature, edition.productId)) {
                            return <IconWrapper key={edition.contentful_id} />;
                          }
                          return (
                            <IconWrapper>
                              <Icon key={edition.id} name={'success'} group="state" size={4} />
                            </IconWrapper>
                          );
                        })}
                      </RowSpecsContainer>
                      {index !== feature.subFeatures.length - 1 && <HorizontalDivider />}
                    </>
                  ))}
              </AllSpecsWrapper>
            </RowContainer>
          ))}
        </FeaturesRow>
      </ContentContainer>
    </Container>
  );
};

const EditionsHeader: React.FC<{ sticky?: boolean; editions: any[] }> = ({ sticky, editions }) => {
  return (
    <TopContainer sticky={sticky}>
      <HeaderRow>
        <TableCaptionContainer />
        <HPRowContainer>
          {editions?.map((edition, index) => {
            if (index >= 4) {
              return null;
            }
            const priceString =
              (edition.detailAndPricing?.currencySymbol || '') +
              (edition.dxlInformation?.price ? edition.dxlInformation.price[0].price.toFixed(2) : '');
            return (
              <ProductHeader
                id={edition.id}
                name={edition.dxlInformation?.name || edition.name}
                price={priceString}
                key={edition.id}
              />
            );
          })}
        </HPRowContainer>
      </HeaderRow>
    </TopContainer>
  );
};

const Caption = ({ toggleValue, toggleAll }: { toggleValue: boolean; toggleAll: () => void }) => {
  return (
    <>
      <TableElementContainer>
        <IconWrapper isGrey noMargin>
          <Icon name={'success'} group={'state'} size={4} />
        </IconWrapper>
        Characteristic partially included.
      </TableElementContainer>

      <TableElementContainer>
        <Icon name={'success'} group={'state'} size={4} />
        Characteristic included.
      </TableElementContainer>

      <TableElementContainer positionAtBottom>
        <Toggle
          id="expand-all-toggle"
          data-selector="expand-all-toggle"
          name="expand-all-toggle"
          value={'expandAllToggle'}
          align="left"
          showIcons={false}
          isActive={toggleValue}
          onChange={toggleAll}
        >
          {toggleValue ? 'Colapse all' : 'Expand all'}
        </Toggle>
      </TableElementContainer>
    </>
  );
};

const IncludedIcon: React.FC<{ feature: Feature; edition: ProductBundle }> = ({ feature, edition }) => {
  // Initialize counters for included subfeatures
  let includedCount = 0;
  const totalSubfeatures = feature.subFeatures.length;

  feature.subFeatures.forEach(subFeature => {
    if (isEditionIncluded(subFeature, edition.productId)) {
      includedCount++;
    }
  });

  let partialIncluded;
  if (includedCount === totalSubfeatures) {
    partialIncluded = false;
  } else if (includedCount > 0) {
    partialIncluded = true;
  } else {
    return <IconWrapper />;
  }

  return (
    <IconWrapper isGrey={partialIncluded}>
      <Icon key={edition.id} name={'success'} group={'state'} size={4} />
    </IconWrapper>
  );
};

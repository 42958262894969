import { useMemo } from 'react';

import { rem } from 'polished';
import { useLocation } from '@reach/router';

import Button from '@legacy-vfuk/core-button';
import IconButton from '@legacy-vfuk/core-icon-button';
import TextInput from '@legacy-vfuk/core-text-input';

import { useBasket } from '@/hooks';
import { ProductBundle } from '@/types/Product.types';
import { IInfoAndCta } from './InfoAndCta.types';

import {
  ButtonWrapper,
  IconButtonWrapper,
  InfoAndCtaInner,
  InfoAndCtaWrapper,
  InputWrapper,
} from './InfoAndCta.styles';
import { getOpcoUrl } from '@/helpers/prefixHelper';

const InfoAndCta = ({ item, itemStepName, buttonStyling, useSimplePrice, mainItem }: IInfoAndCta) => {
  const { pathname } = useLocation();

  const { showDivider, buttonsAction, msftProduct } = item;
  const dataSelector = item.name ?? item.dxlInformation?.name ?? '';

  const initialQty =
    useMemo(() => {
      if (
        (itemStepName?.includes('Bundle') && msftProduct) ||
        pathname.includes(getOpcoUrl('nce-microsoft-365-business'))
      ) {
        return 1;
      }

      if ((itemStepName === 'Service Extra' && msftProduct) || pathname.includes('apphelp-microsoft-365-migration')) {
        return 5;
      }
    }, [itemStepName, msftProduct]) ?? 1;

  const mainProduct = useMemo(() => {
    if (!!mainItem && mainItem.productId !== item.productId) {
      return mainItem;
    }
  }, [mainItem, item]) as ProductBundle;

  const thirdEntryPrice =
    useMemo(() => {
      if (useSimplePrice) {
        return item.dxlInformation?.price[0].price;
      } else if (
        (!useSimplePrice && itemStepName === 'Service Extra') ||
        pathname.includes('apphelp-microsoft-365-migration')
      ) {
        return item.dxlInformation?.price.find(price => price.name === 'Cost2')?.price;
      } else {
        return item.dxlInformation?.price.find(price => price.name === 'Cost1')?.price;
      }
    }, [useSimplePrice, item]) ?? '';

  const fourthEntryPrice =
    (item.detailAndPricing?.fourthEntry && item.dxlInformation?.price.find(price => price.name === 'Cost2')?.price) ||
    '';

  const {
    isWithQty,
    quantity,
    inBasket,
    buttonText,
    handleDecreaseQty,
    handleIncreaseQty,
    handleButtonAction,
    handleChangeQuantity,
    isButtonDisabled,
  } = useBasket({
    item,
    pathname,
    initialQty,
  });

  return (
    <InfoAndCtaWrapper direction="column" mt="auto" pt={5} pb={5} px={5}>
      <InfoAndCtaInner
        direction="row"
        spacing={5}
        {...(showDivider && {
          borderTop: `${rem('1px')} solid`,
          borderColor: 'disabled.default',
          pt: rem('20px'),
        })}
      >
        {isWithQty && (
          <InputWrapper>
            <IconButtonWrapper ml={1}>
              <IconButton
                {...(quantity === 0 && { state: 'disabled' })}
                srText="Decrease quantity"
                data-selector="bundleConfig-amount"
                size={1}
                icon={{ name: 'minus' }}
                onClick={handleDecreaseQty}
                dataAttributes={{
                  value: String(quantity),
                }}
                dataSelectorPrefix={dataSelector.toLowerCase()}
              />
            </IconButtonWrapper>

            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore: Necessary to use TextInput instead the suggested component */}
            <TextInput
              id="action"
              type="number"
              placeholder="0"
              value={String(quantity)}
              onChange={handleChangeQuantity}
              {...(dataSelector && {
                dataAttributes: {
                  'data-stepname': itemStepName?.includes('Bundle') ? 'Bundle' : itemStepName,
                },
                dataSelectorPrefix: dataSelector.toLowerCase(),
              })}
            />
            <IconButtonWrapper mr={1} right={0}>
              <IconButton
                srText="Increase quantity"
                data-selector="bundleConfig-amount"
                size={1}
                icon={{ name: 'plus' }}
                onClick={handleIncreaseQty}
                dataAttributes={{
                  value: String(quantity),
                }}
                dataSelectorPrefix={dataSelector.toLowerCase()}
              />
            </IconButtonWrapper>
          </InputWrapper>
        )}

        <ButtonWrapper
          inBasket={inBasket}
          buttonsAction={buttonsAction}
          buttonBackgroundColor={buttonStyling?.backgroundColor}
          buttonTextColor={buttonStyling?.textColor}
        >
          <Button
            {...(dataSelector && {
              dataAttributes: {
                'data-price': `€${thirdEntryPrice}${fourthEntryPrice && ', €' + fourthEntryPrice}`,
              },
              dataSelectorPrefix: `pricing card-${dataSelector.toLowerCase()}`,
            })}
            data-selector="MS365-BundleConfig-Button"
            id="basketButtonAction"
            text={buttonText()}
            state={isButtonDisabled() ? 'disabled' : undefined}
            onClick={e => handleButtonAction(e, { itemStepName, mainProduct })}
          />
        </ButtonWrapper>
      </InfoAndCtaInner>
    </InfoAndCtaWrapper>
  );
};

export default InfoAndCta;

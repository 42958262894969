import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react';
import Paragraph from '@legacy-vfuk/core-paragraph';

import { useModalContext } from '@/hooks/modal/useModal';

import { AnimationSurround } from './LoadingCheckoutModal.styles';
import handleTranslation from '@/helpers/handleTranslation';


const LoadingCheckoutModal = () => {
  const { t } = useTranslation();
  const { onClose } = useDisclosure();
  const { isLoadingCheckoutModalOpen } = useModalContext();
  const checkoutLoading = handleTranslation("Please wait for some time for check out ...","Por favor aguarde enquanto processamos o seu pedido...")

  return (
    <Modal isCentered isOpen={isLoadingCheckoutModalOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="max-content">
        <ModalBody>
          <AnimationSurround h="100px">
            <Spinner size="xl" />
            <Paragraph size={2} weight={3}>
              {checkoutLoading}
            </Paragraph>
          </AnimationSurround>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoadingCheckoutModal;

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import FiftyFiftyBanner from '@legacy-vfuk/core-fifty-fifty-banner';
import { getImage, getSrc } from 'gatsby-plugin-image';
import omit from 'lodash/omit';
import { FC } from 'react';

import { FiftyFiftyBannerWrapper } from './SWSectionFiftyFiftyBanner.styles';
import { SWSectionFiftyFiftyBannerProps } from './SWSectionFiftyFiftyBanner.types';
import usingOpcoUrl from '@/helpers/prefixHelper';

const SWSectionFiftyFiftyBanner: FC<SWSectionFiftyFiftyBannerProps> = (data: SWSectionFiftyFiftyBannerProps) => {
  const {
    backgroundColor,
    backgroundImage,
    copyBackground,
    customClass,
    bodyPosition,
    heading,
    primaryButton,
    richTextBody,
  } = data;

  const backgroundImageSrc = getSrc(backgroundImage) || getImage(backgroundImage)?.placeholder?.fallback;

  const richTextBodyJson = richTextBody && JSON.parse(richTextBody.raw);
  const richTextBodyContent = richTextBodyJson && { ...richTextBodyJson, content: richTextBodyJson.content[0].content };

  // Ignoring types that are not in the default of the FiftyFiftyBanner component
  const props = omit(data, [
    'id',
    'backgroundImage',
    'backgroundColor',
    'customClass',
    'heading',
    'name',
    'primaryButton',
    'richTextBody',
  ]);

  return (
    <FiftyFiftyBannerWrapper
      {...(customClass && { className: customClass })}
      backgroundColor={backgroundColor}
      bodyPosition={bodyPosition}
      copyBackground={copyBackground}
      data-selector="fiftyFifty banner"
    >
      <FiftyFiftyBanner
        {...props}
        heading={{ text: heading }}
        text={richTextBodyContent && documentToReactComponents(richTextBodyContent)}
        dataSelectorPrefix={heading?.toLowerCase()}
        {...(primaryButton && {
          primaryButton: {
            text: primaryButton.label,
            href: usingOpcoUrl(primaryButton.url),
            ...(primaryButton.customAttributesJson && {
              htmlAttributes: { ...primaryButton.customAttributesJson },
            }),
          },
        })}
        backgroundImage={{
          sm: {
            src: backgroundImageSrc,
          },
          md: {
            src: backgroundImageSrc,
          },
          lg: {
            src: backgroundImageSrc,
          },
        }}
      />
    </FiftyFiftyBannerWrapper>
  );
};

export default SWSectionFiftyFiftyBanner;

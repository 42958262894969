export type ModalTypes = undefined | 'Login' | 'Error' | 'LoadingCheckout' | 'MsDomain';

type ApiStatusTypes =
  | 'MISSING_LOGIN_HEADER'
  | 'SESSION_EXPIRED'
  | 'INVALID_TOKEN'
  | 'NOT_ELIGIBLE'
  | 'MISSING_ELIGIBILITY_HEADER'
  | 'NO_SUBSCRIPTION'
  | 'INVALID_ORDER_PAYLOAD'
  | 'INVALID_CSRF_TOKEN'
  | 'INVALID_REQUEST'
  | null
  | undefined;

export type InitialState = {
  isModalOpen: boolean;
  isLoginModalOpen: boolean;
  isErrorModalOpen: boolean;
  isLoadingCheckoutModalOpen: boolean;
  isMsDomainModalOpen: boolean;
  errorInfo: {
    // statusType?: string | null | undefined;
    statusType?: ApiStatusTypes | string;

    statusCode?: number | null;
    errorMessage?: string;
    requestUrl: 'login' | 'catalogues' | 'users' | 'product-order' | 'eligibility' | 'mail' | null;
  };
};

type ModalStatusWithType = Pick<InitialState, 'isModalOpen'> & { modalType?: ModalTypes };

type ErrorInfoPayload = Pick<InitialState, 'errorInfo'>;

type DispatchTypes = {
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void;
  setErrorInfo: (errorInfo: InitialState['errorInfo']) => void;
};

export type ContextTypes = InitialState & DispatchTypes;

export enum ACTIONS {
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  SET_ERROR_INFO = 'SET_ERROR_INFO',
}

type ToggleModal = {
  type: ACTIONS.TOGGLE_MODAL;
  payload: ModalStatusWithType;
};

type SetErrorInfo = {
  type: ACTIONS.SET_ERROR_INFO;
  payload: ErrorInfoPayload;
};

export type Actions = ToggleModal | SetErrorInfo;

import React, { useEffect } from 'react';
import { ContentWrapper, FormParagraph, Title, UnderlinedParagraph } from './RegisterForms.styles';
import Form from '@/components/pageBuilder/Elements/Form';
import { companyDetailsFormConfig, billingDetailsForm } from './FormConfigs/CompanyFormConfig';
import { useFormHandler } from './useFormHandler';
import { Spacer } from '../RegisterAccount.styles';
import { useFormState } from './FormContext';
import { IFormProps } from './RegisterForms.types';

export const CompanyDetails = (props: IFormProps) => {
  const { form, handleInputChange, handleInputBlur } = useFormHandler('company');

  const {
    form: billingForm,
    handleInputChange: handleBillingInputChance,
    handleInputBlur: handleBillingInputBlur,
  } = useFormHandler('billing');

  return (
    <ContentWrapper>
      <Title>Company details</Title>
      <Form
        formInputs={form}
        onInputChange={handleInputChange}
        onInputBlur={handleInputBlur}
        shouldHaveFullWidthOnMobile={false}
        formValidation={isValid => {
          props.validCallback(isValid, 'company');
        }}
        formSubmit={form => props.onSubmit && props.onSubmit(form, 'company')}
      />
      <Spacer h={30} w={100} />

      <Title>Billing Details</Title>
      <Form
        formInputs={billingForm}
        onInputChange={handleBillingInputChance}
        onInputBlur={handleBillingInputBlur}
        shouldHaveFullWidthOnMobile={false}
        formValidation={isValid => {
          props.validCallback(isValid, 'billing');
        }}
        formSubmit={form => props.onSubmit && props.onSubmit(form, 'billing')}
      />
      <Spacer h={20} w={100} />
      <FormParagraph size={12}>
        {
          'NOTE: The use of special characters “\\”, “[“, “”, “&;”, “;”, “ “ , “{“, “}’, “]”, “/”, “///”, ”%” , ” ` “ , “ ^ ” are not allowed'
        }
      </FormParagraph>
      <Spacer h={20} w={100} />
      <FormParagraph size={18}>
        {`By proceeding and clicking on “Create my Account” you are accepting the `}
        <UnderlinedParagraph>Terms and Conditions and Privacy Supplement</UnderlinedParagraph>
        {` current.`}
      </FormParagraph>
    </ContentWrapper>
  );
};
